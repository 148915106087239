import { HTMLTable, Icon } from "@blueprintjs/core";
import React, { ReactNode, useState } from "react";
import { createUseStyles } from "react-jss";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate } from "react-router-dom";
import PlatformTablePagination from "./PlatformTablePagination";

interface Column<T> {
  id: string;
  header: string;
  render: (item: T) => ReactNode;
  sortable?: boolean;
  href?: string | ((item: T) => string);
}

interface StyleProps {
  columnCount: number;
  pageSize: number;
}

const useStyles = createUseStyles({
  table: {
    width: "100%",
    fontFamily: "Barlow",
    tableLayout: "fixed",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    minHeight: 0,
  },
  row: {
    display: "flex",
    transition: "background-color 0.01s",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
  column: {
    width: (props: StyleProps) => `${100 / props.columnCount}%`,
  },
  pagination: {
    fontFamily: "Barlow",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1rem",
  },
  caretIcon: {
    flexShrink: 0,
    transition: "opacity 0.2s",
  },
  headerCellHover: {
    cursor: "pointer",
    "&:hover $caretIcon": {
      opacity: 1,
    },
  },
  cell: {
    height: "40px",
    width: "calc(100% + 16px)",
    padding: "10px 10px",
    margin: "-10px -10px",
    display: "block",
    marginRight: "-100px",
  },
  cellLink: {
    extend: "cell",
    textDecoration: "none",
    border: "none",
    color: "white !important",
    "&:hover": {
      marginRight: "-100px",
      textDecoration: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      outline: "none",
    },
  },
  headerCell: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  headerText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    flexGrow: 1,
    marginRight: "6px",
  },
  tableContainer: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
  },
  tableBody: {
    minHeight: 0,
    display: "flex",
    height: (props: StyleProps) => props.pageSize * 42 + 40 + 20, // Row = 42, Header = 40, (Possible scroller + padding) = 20
    marginBottom: 5,
  },
  tableHeader: {
    display: "flex",
    height: 40,
  },
  tableContent: {
    flex: 1,
    minHeight: 0,
    overflowY: "auto",
  },
  tableRow: {
    display: "flex",
    height: 42,
  },
  skeletonCell: {
    width: "40%",
  },
});

export interface TableOrdering {
  field: string;
  order: "ASC" | "DESC" | null;
}

interface PlatformTableProps<T> {
  data: T[];
  columns: Column<T>[];
  pageSize: number;
  interactive?: boolean;
  numberOfAllItems: number;
  glimmer?: boolean;
  currentPage?: number;
  handlePageChange: (newPage: number) => void;
  ordering?: TableOrdering[];
  onOrderingChange?: (ordering: TableOrdering[]) => void;
  onPageSizeChange: (pageSize: number) => void;
}

function getOrderingForKey(ordering: TableOrdering[], key: string) {
  return ordering.find((o) => o.field === key)?.order ?? null;
}

export default function PlatformTable<T extends object>({
  data,
  interactive = false,
  columns,
  pageSize,
  numberOfAllItems,
  glimmer = false,
  currentPage = 1,
  ordering = [],
  onOrderingChange,
  onPageSizeChange,
  handlePageChange,
}: PlatformTableProps<T>): React.ReactElement {
  const classes = useStyles({ columnCount: columns.length, pageSize });
  const [hoveredColumn, setHoveredColumn] = useState<number | null>(null);

  const handleHeaderClick = (columnId: string) => {
    if (!onOrderingChange) {
      return;
    }

    const currentOrdering = ordering.find((o) => o.field === columnId);
    let newOrdering: TableOrdering[];

    if (!currentOrdering) {
      // First click: Add ascending order
      newOrdering = [{ field: columnId, order: "ASC" }];
    } else if (currentOrdering.order === "ASC") {
      // Second click: Change to descending order
      newOrdering = [{ field: columnId, order: "DESC" }];
    } else {
      // Third click: Remove ordering for this column
      newOrdering = [{ field: columnId, order: null }];
    }

    onOrderingChange(newOrdering);
  };

  const navigate = useNavigate();

  const renderCell = (item: T, col: Column<T>) => {
    const content = col.render(item);
    if (col.href) {
      const hrefValue =
        typeof col.href === "function" ? col.href(item) : col.href;
      return (
        <a
          href={hrefValue}
          className={classes.cellLink}
          onClick={(e) => {
            if (!e.ctrlKey && !e.metaKey && !e.shiftKey && e.button !== 1) {
              e.preventDefault();
              navigate(hrefValue);
            }
          }}
        >
          {content}
        </a>
      );
    }
    return <div className={classes.cell}>{content}</div>;
  };

  return (
    <SkeletonTheme baseColor="#313740" highlightColor="#657285" duration={3}>
      <div className={classes.tableContainer}>
        <div className={classes.tableBody}>
          <HTMLTable className={classes.table}>
            <thead>
              <tr className={classes.tableHeader}>
                {columns.map((col, index) => (
                  <th
                    key={index}
                    className={`${classes.column} ${classes.headerCell} ${
                      col.sortable ? classes.headerCellHover : ""
                    }`}
                    onMouseEnter={() => setHoveredColumn(index)}
                    onMouseLeave={() => setHoveredColumn(null)}
                    onClick={() => col.sortable && handleHeaderClick(col.id)}
                    title={col.header}
                  >
                    <span className={classes.headerText}>{col.header}</span>
                    {col.sortable && (
                      <Icon
                        className={classes.caretIcon}
                        icon={
                          getOrderingForKey(ordering, col.id) === "DESC"
                            ? "caret-down"
                            : getOrderingForKey(ordering, col.id) === "ASC"
                            ? "caret-up"
                            : "double-caret-vertical"
                        }
                        style={{
                          opacity:
                            hoveredColumn === index ||
                            getOrderingForKey(ordering, col.id) !== null
                              ? 1
                              : 0,
                        }}
                      />
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className={classes.tableContent}>
              {glimmer ? (
                Array.from({ length: pageSize }).map((_, index) => (
                  <tr key={index} className={classes.tableRow}>
                    {columns.map((_, colIndex) => (
                      <td key={colIndex} className={classes.column}>
                        <div className={classes.cell}>
                          <div className={classes.skeletonCell}>
                            <Skeleton />
                          </div>
                        </div>
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <>
                  {data.map((item, index) => (
                    <tr key={index} className={classes.row}>
                      {columns.map((col, colIndex) => (
                        <td key={colIndex} className={classes.column}>
                          {renderCell(item, col)}
                        </td>
                      ))}
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </HTMLTable>
        </div>
        {handlePageChange && onPageSizeChange && (
          <div>
            <PlatformTablePagination
              onPageSizeChange={onPageSizeChange}
              currentPage={currentPage}
              pageSize={pageSize}
              totalItems={numberOfAllItems ?? 0}
              handlePageChange={handlePageChange}
            />
          </div>
        )}
      </div>
    </SkeletonTheme>
  );
}
