import { Spinner } from "@blueprintjs/core";
import mapboxgl, { LngLatBounds, Map, Marker } from "mapbox-gl";
import { useEffect, useRef } from "react";
import { initializeMap } from "src/ui/utils/mapUtils";
import { MAPBOX_TOKEN } from "../../barcelonaLocations";
import useResizeObserver from "../useResizeObserver";

mapboxgl.accessToken = MAPBOX_TOKEN;

interface PlatformSitePropertyMapProps {
  height?: string;
  latitude?: number | null;
  longitude?: number | null;
  disabled?: boolean;
  loading?: boolean;
}

export default function PlatformSitePropertyMap({
  height = "200px",
  latitude = null,
  longitude = null,
  disabled = false,
  loading = false,
}: PlatformSitePropertyMapProps) {
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<Map | null>(null);

  const dimensions = useResizeObserver(mapContainer);

  const markersRef = useRef<Marker[]>([]);

  useEffect(() => {
    if (map.current || !mapContainer.current) {
      return;
    }

    map.current = initializeMap({
      container: mapContainer.current,
      center: [longitude ?? -0.1276, latitude ?? 51.5074], // Center on London
      zoom: longitude && latitude ? 16 : 5, // Zoom out if centered on London
    });
  }, [latitude, longitude]);

  useEffect(() => {
    if (!map.current) {
      return;
    }

    // Remove existing marker
    markersRef.current.forEach((marker) => marker.remove());
    markersRef.current = [];

    // Prepare to fit marker within the view
    const bounds = new LngLatBounds();

    // Create a marker and add to the map
    if (map.current && longitude !== null && latitude !== null) {
      const marker = new Marker({ color: "#ff5257" })
        .setLngLat([longitude, latitude])
        .addTo(map.current);

      // Extend the bounds to include marker's position
      bounds.extend([longitude, latitude]);
      markersRef.current.push(marker);
    }

    if (markersRef.current.length > 0) {
      map.current.fitBounds(bounds, {
        maxZoom: 16,
        animate: false,
      });
    }
  }, [latitude, longitude]);

  useEffect(() => {
    map.current?.resize();
  }, [dimensions]);

  return (
    <div style={{ position: "relative", height, width: "100%" }}>
      <div
        ref={mapContainer}
        style={{
          height,
          width: "100%",
          borderRadius: "3px",
        }}
      />
      {(disabled || loading) && (
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.05)",
            backdropFilter: "blur(2px)",
            zIndex: 3,
          }}
        >
          {loading && <Spinner />}
        </div>
      )}
    </div>
  );
}
