import { Battery } from "@ec1/types/Battery";
import { Build, Power, PowerOff } from "@mui/icons-material";
import { createUseStyles } from "react-jss";

interface PlatformBatteryClusterPopoverContentProps {
  batteries: Battery[];
}

const useStyles = createUseStyles({
  container: {
    fontFamily: "Barlow",
    padding: 10,
    cursor: "default",
    minWidth: 200,
  },
  header: {
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
  },
  divider: {
    width: "calc(100% - 4px)",
    height: 1,
    background: "#555",
    marginTop: 10,
    marginLeft: 2,
  },
  batteryInfoRow: {
    marginTop: 14,
    display: "flex",
    flexDirection: "row",
  },
  powerIconOffline: {
    fontSize: "18px !important",
    color: "#ec9a3c",
  },
  powerIconFault: {
    fontSize: "18px !important",
    color: "#c20a0a",
    padding: 2, // Padding because the icon is big
  },
  powerIconActive: {
    fontSize: "18px !important",
    color: "#238551",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  iconContainer: {
    marginRight: 3,
    marginLeft: -2,
  },
  boldText: {
    fontFamily: "Barlow",
    fontWeight: 500,
  },
});

export default function PlatformBatteryClusterPopoverContent({
  batteries,
}: PlatformBatteryClusterPopoverContentProps) {
  const classes = useStyles();

  const onlineCount = batteries.filter(
    (battery) => battery.station_state === "ONLINE"
  ).length;
  const offlineCount = batteries.filter(
    (battery) => battery.station_state === "OFFLINE"
  ).length;
  const faultCount = batteries.filter(
    (battery) => battery.station_state === "FAULT"
  ).length;

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div>Cluster Summary</div>
      </div>

      <div className={classes.divider}></div>

      {onlineCount > 0 && (
        <div className={classes.batteryInfoRow}>
          <div className={classes.flexRow}>
            <div className={classes.iconContainer}>
              <Power className={classes.powerIconActive} />
            </div>
            <div>
              {`Online ${onlineCount > 1 ? "batteries" : "battery"}: `}
              <span className={classes.boldText}>{onlineCount}</span>
            </div>
          </div>
        </div>
      )}

      {offlineCount > 0 && (
        <div className={classes.batteryInfoRow}>
          <div className={classes.flexRow}>
            <div className={classes.iconContainer}>
              <PowerOff className={classes.powerIconOffline} />
            </div>
            <div>
              {`Offline ${offlineCount > 1 ? "batteries" : "battery"}: `}
              <span className={classes.boldText}>{offlineCount}</span>
            </div>
          </div>
        </div>
      )}

      {faultCount > 0 && (
        <div className={classes.batteryInfoRow}>
          <div className={classes.flexRow}>
            <div className={classes.iconContainer}>
              <Build className={classes.powerIconFault} />
            </div>
            <div>
              {`Fault${faultCount > 1 ? "s" : ""}: `}
              <span className={classes.boldText}>{faultCount}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
