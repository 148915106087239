import {
  Button,
  ButtonGroup,
  IconSize,
  InputGroup,
  Spinner,
  Switch,
} from "@blueprintjs/core";
import { AssetEnrollmentCandidate } from "@ec1/types/AssetEnrollmentCandidate";
import { AssetEnrollmentSession } from "@ec1/types/AssetEnrollmentSession";
import { Vendor } from "@ec1/types/Vendor";
import { VendorAccount } from "@ec1/types/VendorAccount";
import Fuse from "fuse.js";
import { useEffect, useMemo, useState } from "react";
import { createUseStyles } from "react-jss";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch, useFetcher } from "src/common/fetcher/effects";
import { useBrands } from "src/core/CoreDataContext";
import PlatformVendorAccountDeviceEnrolmentCandidateItem from "./PlatformVendorAccountDeviceEnrolmentCandidateItem.react";

const useStyles = createUseStyles({
  root: {
    width: "100%",
  },
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    fontFamily: "Barlow",
    marginTop: "12px",
    alignItems: "center",
  },
  loadingText: {
    marginTop: "24px",
  },
  contentContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  candidatesWrapper: {
    width: "700px",
  },
  controlsHeader: {
    marginBottom: "12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
  },
  enrolledSwitch: {
    margin: "8px 24px",
    outline: "none",
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
    gap: "12px",
  },
  pageInfo: {
    fontFamily: "Barlow",
    fontSize: "14px",
  },
  searchContainer: {
    marginBottom: "16px",
    width: "100%",
  },
});

const ITEMS_PER_PAGE = 10;

// Configure Fuse options for fuzzy search
const fuseOptions = {
  keys: [
    {
      name: "description",
      weight: 1,
    },
    {
      name: "location",
      weight: 2,
    },
    {
      name: "name",
      weight: 2,
    },
  ],
  threshold: 0.6,
  ignoreLocation: true,
  tokenize: true,
  matchAllTokens: false,
  findAllMatches: true,
  minMatchCharLength: 2,
};
export default function PlatformVendorAccountDeviceEnrolmentPage() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [enrolledDevices, setEnrolledDevices] = useState<string[]>([]);
  const [selectedDevices, setSelectedDevices] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const { accountId, deviceEnrollmentSessionId } = useParams<{
    accountId: string;
    deviceEnrollmentSessionId?: string;
  }>();

  const createEnrollmentSessionUrl = useMemo(() => {
    if (deviceEnrollmentSessionId) {
      return null;
    }
    return "/api/devices/enrollment-session/";
  }, [deviceEnrollmentSessionId]);

  const [sessionStatus, setSessionStatus] = useState<string | null>(null);
  const [showEnrolledDevices, setShowEnrolledDevices] = useState(true);

  const currentEnrollmentSessionUrl = useMemo(() => {
    if (deviceEnrollmentSessionId) {
      return `/api/devices/enrollment-session/${deviceEnrollmentSessionId}/`;
    }
    return null;
  }, [deviceEnrollmentSessionId]);

  const { data: newEnrollmentSessionData } = useFetch<AssetEnrollmentSession>(
    createEnrollmentSessionUrl,
    {
      method: "POST",
      body: JSON.stringify({ vendor_account: parseInt(accountId ?? "0") }),
    }
  );

  const { data: currentEnrollmentSessionData } =
    useFetch<AssetEnrollmentSession>(currentEnrollmentSessionUrl, {
      pollingInterval: [
        "queued",
        "processing",
        "",
        "selection_request",
      ].includes(sessionStatus ?? "")
        ? 2000
        : 0,
    });

  const { data: candidatesData } = useFetch<{
    results: AssetEnrollmentCandidate[];
  }>(
    sessionStatus === "selection_request"
      ? `/api/asset/vendor/account/enrollment-candidates/?session=${currentEnrollmentSessionData?.uuid}`
      : null
  );

  const { data: vendorAccount } = useFetch<VendorAccount>(
    `/api/asset/vendor/account/${accountId}/`
  );

  const fetchVendorUrl = useMemo(() => {
    if (vendorAccount?.vendor) {
      return `/api/asset/vendor/${vendorAccount.vendor}/`;
    }
    return null;
  }, [vendorAccount]);

  const { data: vendor } = useFetch<Vendor>(fetchVendorUrl);

  // Create Fuse instance for fuzzy search
  const fuse = useMemo(() => {
    if (!candidatesData?.results) {
      return null;
    }
    return new Fuse(candidatesData.results, fuseOptions);
  }, [candidatesData?.results]);

  // Filter candidates based on enrollment status and search query
  const filteredCandidates = useMemo(() => {
    if (!candidatesData?.results) {
      return [];
    }

    let results = candidatesData.results;

    // Filter by enrollment status
    if (!showEnrolledDevices) {
      results = results.filter(
        (x) => !enrolledDevices.includes(x.unique_id ?? "")
      );
    }

    // Apply search filter if query exists
    if (searchQuery && fuse) {
      const searchResults = fuse.search(searchQuery);
      results = searchResults.map((result) => result.item);
    }

    return results;
  }, [
    candidatesData?.results,
    showEnrolledDevices,
    enrolledDevices,
    searchQuery,
    fuse,
  ]);

  const totalPages = Math.ceil(filteredCandidates.length / ITEMS_PER_PAGE);
  const paginatedCandidates = useMemo(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    return filteredCandidates.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  }, [filteredCandidates, currentPage]);

  useEffect(() => {
    setCurrentPage(1); // Reset to first page when filters change
  }, [showEnrolledDevices, searchQuery]);

  useEffect(() => {
    if (newEnrollmentSessionData?.uuid) {
      navigate(
        `/vendor/account/${accountId}/asset-enrollment/${newEnrollmentSessionData?.uuid}`
      );
    }
  }, [accountId, navigate, newEnrollmentSessionData?.uuid]);

  useEffect(() => {
    if (sessionStatus === "completed") {
      navigate(`/vendor/account/${accountId}/`);
    }
  }, [accountId, navigate, sessionStatus]);

  useEffect(() => {
    setSessionStatus(currentEnrollmentSessionData?.status ?? null);
    setEnrolledDevices(currentEnrollmentSessionData?.enrolled_assets ?? []);
  }, [currentEnrollmentSessionData]);

  const [patchUrl, patchBody] = useMemo(() => {
    return [
      `/api/devices/enrollment-session/${deviceEnrollmentSessionId}/`,
      JSON.stringify({ selected_assets: selectedDevices }),
    ];
  }, [deviceEnrollmentSessionId, selectedDevices]);

  const {
    data: fetchedPatchData,
    fetch: fetchPatch,
    isLoading: isFetchingPatch,
  } = useFetcher<AssetEnrollmentSession>(patchUrl, {
    method: "PATCH",
    body: patchBody,
  });

  useEffect(() => {
    setSessionStatus(fetchedPatchData?.status ?? null);
  }, [fetchedPatchData]);

  const brands = useBrands();

  const currentBrand = useMemo(() => {
    const filteredBrands = brands.filter((b) => b.id === vendor?.brand);
    if (filteredBrands?.length > 0) {
      return filteredBrands[0];
    }
    return null;
  }, [brands, vendor?.brand]);

  return (
    <div className={classes.root}>
      {["queued", "processing", null].includes(String(sessionStatus)) && (
        <div className={classes.loadingContainer}>
          <div>
            <Spinner />
          </div>
          <div className={classes.loadingText}>
            Fetching devices from server
          </div>
        </div>
      )}

      <div className={classes.contentContainer}>
        {
          <div className={classes.candidatesWrapper}>
            <div
              style={{
                fontFamily: "Barlow",
                padding: "12px 0px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  fontFamily: "Barlow",

                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex" }}>
                  {currentBrand ? (
                    <img
                      alt="brand-icon"
                      src={currentBrand.icon}
                      height={IconSize.STANDARD}
                    />
                  ) : null}
                </div>
                <div style={{ marginLeft: "8px" }}>
                  {currentBrand?.display_name ?? ""}
                </div>
              </div>
              <span
                style={{
                  whiteSpace: "nowrap",

                  maxWidth: "200px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                Account: {vendorAccount?.vendor_account_uid ?? "N/A"}
              </span>
            </div>
            <div className={classes.searchContainer}>
              <InputGroup
                leftIcon="search"
                placeholder="Search devices..."
                onChange={(e) => setSearchQuery(e.target.value)}
                value={searchQuery}
              />
            </div>
            <div className={classes.controlsHeader}>
              <div>{`${selectedDevices.length} assets selected`}</div>
              <div className={classes.buttonsContainer}>
                <ButtonGroup>
                  <Switch
                    className={classes.enrolledSwitch}
                    inline
                    label="Show enrolled devices"
                    checked={showEnrolledDevices}
                    onChange={() => {
                      setShowEnrolledDevices((prev) => !prev);
                    }}
                  />
                  <Button
                    small
                    disabled={selectedDevices?.length === 0}
                    rightIcon={
                      isFetchingPatch ? (
                        <Spinner size={IconSize.STANDARD} />
                      ) : undefined
                    }
                    onClick={() => {
                      fetchPatch();
                    }}
                  >
                    Continue
                  </Button>
                </ButtonGroup>
              </div>
            </div>
            {paginatedCandidates.map((x) => (
              <PlatformVendorAccountDeviceEnrolmentCandidateItem
                key={x.unique_id}
                candidate={x}
                disabled={enrolledDevices.includes(x.unique_id ?? "")}
                selected={selectedDevices.includes(x.unique_id ?? "")}
                onSelect={() => {
                  setSelectedDevices((prev) =>
                    [
                      ...prev.filter((d) => d !== x.unique_id),
                      x.unique_id ?? "",
                    ].filter((d) => d.length > 0)
                  );
                }}
                onUnselect={() => {
                  setSelectedDevices((prev) =>
                    [...prev.filter((d) => d !== x.unique_id)].filter(
                      (d) => d.length > 0
                    )
                  );
                }}
              />
            ))}
            {totalPages > 1 && (
              <div className={classes.paginationContainer}>
                <Button
                  small
                  disabled={currentPage === 1}
                  onClick={() =>
                    setCurrentPage((prev) => Math.max(1, prev - 1))
                  }
                >
                  Previous
                </Button>
                <span className={classes.pageInfo}>
                  Page {currentPage} of {totalPages}
                </span>
                <Button
                  small
                  disabled={currentPage === totalPages}
                  onClick={() =>
                    setCurrentPage((prev) => Math.min(totalPages, prev + 1))
                  }
                >
                  Next
                </Button>
              </div>
            )}
          </div>
        }
      </div>
    </div>
  );
}
