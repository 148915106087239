import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import SpinningLogo from "src/ui/logo/SpinningLogo.react";
import useVisibilityState from "../browser/useVisibilityState";
import { refreshAuthenticationTokens } from "./authenticationFetchers";
import {
  IAuthenticationTokens,
  isJWTValid,
  parseJWT,
  shouldRefreshJWTTokens,
} from "./authenticationJWTUtils";
import {
  getAuthenticationTokensFromLocalStorage,
  removeAuthenticationTokensFromLocalStorage,
  setAuthenticationTokensInLocalStorage,
} from "./authenticationLocalStorage";

export const TOKEN_RENEW_WINDOW = 60; // Time window for token renew (in seconds)

interface IAuthContext {
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  requestReset: (email: string) => Promise<boolean>;
  verifyToken: (token: string) => Promise<boolean>;
  resetPassword: (token: string, newPassword: string) => Promise<boolean>;
  setAuthError: (error: string | null) => void;
  isTokenExpiring: () => boolean;
  getAccessToken: () => string | null;
  authError: string | null;
  isLoggedIn: boolean;
  tokens: IAuthenticationTokens | null;
  isLoading: boolean;
}

const AuthContext = createContext<IAuthContext | undefined>(undefined);

interface IAuthProviderProps {
  children: ReactNode;
}

async function getErrorMessageJson(response: any) {
  try {
    const contentType = response.headers.get("content-type");

    let json;

    if (contentType && contentType.includes("application/json")) {
      json = await response.json();
    }

    const firstPair = json ? Object.values(json)[0] : null;

    if (Array.isArray(firstPair) && firstPair.length > 0) {
      return firstPair[0];
    } else if (typeof firstPair === "string") {
      return firstPair;
    }
    return "No error message found.";
  } catch (error) {
    return "An error has occurred.";
  }
}

async function getErrorMessageHTML(response: any) {
  try {
    const text = await response.text();
    const match = text.match(/<pre class="exception_value">(.+?)<\/pre>/);
    return match && match[1] ? match[1] : "An error has occurred.";
  } catch (error) {
    return "An error has occurred.";
  }
}

export const AuthenticationProvider: React.FC<IAuthProviderProps> = ({
  children,
}) => {
  const [authError, setAuthError] = useState<string | null>(null);
  const [tokens, setTokens] = useState<IAuthenticationTokens | null>(
    getAuthenticationTokensFromLocalStorage()
  );

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(
    Boolean(tokens) &&
      (isJWTValid(tokens?.access) || isJWTValid(tokens?.refresh))
  );

  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const login = useCallback(async (email: string, password: string) => {
    try {
      const response = await fetch("/api/jwt/login/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username: email, password }),
      });

      if (!response.ok) {
        throw new Error(await getErrorMessageJson(response));
      }

      const data = await response.json();
      const newTokens: IAuthenticationTokens = {
        access: data.access,
        refresh: data.refresh,
      };
      setTokens(newTokens);
      setIsLoggedIn(true);
      setAuthError(null);

      // Clear any leftover session data
      localStorage.removeItem("sessionExpiryData");

      // Store tokens in localStorage
      setAuthenticationTokensInLocalStorage(newTokens);
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        setAuthError(error.message || "An error has occurred.");
      } else {
        console.error("An error has occurred.");
        setAuthError("An error has occurred.");
      }
      setTokens(null);
      setIsLoggedIn(false);
      removeAuthenticationTokensFromLocalStorage();
    }
  }, []);

  const logout = useCallback(() => {
    setTokens(null);
    setIsLoggedIn(false);
    removeAuthenticationTokensFromLocalStorage();
  }, []);

  // Handle storage changes (e.g., multi-tab logout/login)
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === "tokens" && event.newValue !== null) {
        const newTokens: IAuthenticationTokens = JSON.parse(event.newValue);
        setTokens(newTokens);
        setIsLoggedIn(true);
      } else if (event.key === "tokens") {
        setTokens(null);
        setIsLoggedIn(false);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const requestReset = useCallback(async (email: string) => {
    try {
      const response = await fetch("/auth/reset-password/request-reset/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error(await getErrorMessageHTML(response));
      }

      return true;
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        setAuthError(error.message || "An error has occurred.");
      } else {
        console.error("An error has occurred.");
        setAuthError("An error has occurred.");
      }
      return false;
    }
  }, []);

  const verifyToken = useCallback(async (token: string) => {
    try {
      const response = await fetch("/auth/reset-password/verify-token/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token }),
      });

      if (!response.ok) {
        throw new Error(await getErrorMessageJson(response));
      }

      return true;
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        setAuthError(error.message || "An error has occurred.");
      } else {
        console.error("An error has occurred.");
        setAuthError("An error has occurred.");
      }
      return false;
    }
  }, []);

  const resetPassword = useCallback(
    async (token: string, new_password: string) => {
      try {
        const response = await fetch("/auth/reset-password/reset-password/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token, new_password }),
        });

        if (!response.ok) {
          throw new Error(await getErrorMessageJson(response));
        }

        return true;
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
          setAuthError(error.message || "An error has occurred.");
        } else {
          console.error("An error has occurred.");
          setAuthError("An error has occurred.");
        }
        return false;
      }
    },
    []
  );

  const renewToken = useCallback(async () => {
    if (tokens && tokens.refresh && !isRefreshing) {
      setIsRefreshing(true);
      try {
        const newTokens = await refreshAuthenticationTokens(tokens.refresh);
        setTokens(newTokens);
        setAuthenticationTokensInLocalStorage(newTokens);
        setIsLoggedIn(true);
        setAuthError(null);
      } catch (error) {
        console.error(error);
        setAuthError(error instanceof Error ? error.message : "Unknown Error");
        logout();
      } finally {
        setIsRefreshing(false);
      }
    }
  }, [tokens, isRefreshing, logout]);

  const visibilityState = useVisibilityState();

  // Initialization Effect: Refresh tokens if needed when the provider mounts
  useEffect(() => {
    async function initializeAuth() {
      if (tokens) {
        const accessValid = isJWTValid(tokens.access);
        const refreshValid = isJWTValid(tokens.refresh);

        if (!accessValid && refreshValid) {
          await renewToken();
        } else if (!refreshValid) {
          logout();
        }
      }
      setIsLoading(false);
    }
    initializeAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Run only once on mount

  // Periodically check token validity and renew if necessary
  useEffect(() => {
    if (isLoading) {
      return;
    } // Do not set interval while loading

    async function check() {
      if (visibilityState === "visible") {
        const currentTokens = getAuthenticationTokensFromLocalStorage();
        if (shouldRefreshJWTTokens(currentTokens)) {
          await renewToken();
        }
      }
    }
    check();
    const interval = setInterval(check, 5000);

    return () => clearInterval(interval);
  }, [renewToken, visibilityState, isLoading]);

  // Update localStorage and login state when tokens change
  useEffect(() => {
    if (isLoading) {
      return;
    } // Avoid running during initial load

    if (tokens) {
      const accessValid = isJWTValid(tokens.access);
      const refreshValid = isJWTValid(tokens.refresh);

      if (accessValid) {
        setAuthenticationTokensInLocalStorage(tokens);
      } else if (refreshValid) {
        renewToken();
      } else {
        removeAuthenticationTokensFromLocalStorage();
        setIsLoggedIn(false);
      }
    } else {
      removeAuthenticationTokensFromLocalStorage();
      setIsLoggedIn(false);
    }
  }, [tokens, renewToken, isLoading]);

  const isTokenExpiring = useCallback(() => {
    if (tokens && tokens.access) {
      const decoded = parseJWT(tokens.access);
      const currentTime = Date.now() / 1000;
      return (
        decoded && decoded.exp && decoded.exp - currentTime < TOKEN_RENEW_WINDOW
      );
    }
    return true;
  }, [tokens]);

  const getAccessToken = useCallback((): string | null => {
    if (tokens && isJWTValid(tokens.access)) {
      return tokens.access;
    }
    return null;
  }, [tokens]);

  // Wait for initial loading to complete before rendering children
  if (isLoading) {
    // Imitate main loading screen
    return (
      <div
        className="mainLogo"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div style={{ marginTop: -12 }}>
          <SpinningLogo
            height="56px"
            isLoadingSpinner={true}
            key={String(isLoading)}
            fontFamily='-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'
          />
        </div>
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        requestReset,
        verifyToken,
        resetPassword,
        setAuthError,
        isTokenExpiring,
        getAccessToken,
        authError,
        isLoggedIn,
        tokens,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuthentication(): IAuthContext {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(
      "useAuthentication must be used within an AuthenticationProvider"
    );
  }
  return context;
}

export function useAuthHeaders() {
  const { getAccessToken } = useAuthentication();
  const selectedLabel = localStorage.getItem("SELECTED_ENTERPRISE_LABEL");
  const headers = useMemo(() => {
    const ret: Record<string, string> = {
      "Content-Type": "application/json",
    };
    const token = getAccessToken();
    if (token) {
      ret["Authorization"] = `Bearer ${token}`;
    }
    if (selectedLabel) {
      ret["Enterprise-Label"] = String(selectedLabel);
    }
    return ret;
  }, [getAccessToken, selectedLabel]);

  return headers;
}
