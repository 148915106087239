import { Button, Icon } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { IFilter, IFilterDefinition } from "./PlatformPowerSearchBar.react";

const useStyles = createUseStyles({
  filterChip: {
    fontFamily: "Barlow, sans-serif",
    fontSize: "12px",
    backgroundColor: "#404854",
    padding: "4px 8px",
    color: "white",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    transition: "background-color 0.2s ease",
    "&:hover": {
      backgroundColor: "#4C5561",
    },
  },
  filterName: {
    marginRight: "8px",
    fontWeight: 600,
  },
  filterSelect: {
    fontSize: "12px",
    fontFamily: "Barlow, sans-serif",
    marginRight: "8px",
    background: "transparent",
    border: "none",
    color: "white",
    appearance: "none",
    cursor: "pointer",
    padding: "2px 16px 2px 4px",
    borderRadius: "2px",
    transition: "background-color 0.2s ease",
    "&:hover, &:focus": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
  selectArrow: {
    position: "absolute",
    right: "4px",
    top: "50%",
    transform: "translateY(-50%)",
    pointerEvents: "none",
  },
  filterOption: {
    fontFamily: "Barlow, sans-serif",
    backgroundColor: "#2B343F",
    color: "white",
  },
  filterInput: {
    background: "transparent",
    border: "none",
    color: "white",
    fontFamily: "Barlow, sans-serif",
    fontSize: "12px",
    outline: "none",
    padding: "2px 4px",
    width: "100px",
    transition: "background-color 0.2s ease",
    "&::placeholder": {
      color: "#8A9BA8",
    },
    "&:hover, &:focus": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
  removeFilterButton: {
    marginLeft: "8px",
  },
});

interface PlatformTableFilterChipProps {
  filterDefinition: IFilterDefinition;
  filter: IFilter;
  onRemove: () => void;
  onChange: (value: string) => void;
  onOperatorChange: (operator: string) => void;
  inputRef?: React.RefObject<HTMLInputElement>;
}

const PlatformTableFilterChip: React.FC<PlatformTableFilterChipProps> = ({
  filter,
  filterDefinition,
  onRemove,
  onChange,
  onOperatorChange,
  inputRef,
}) => {
  const classes = useStyles();
  const [selectedOperator, setSelectedOperator] = useState(
    filterDefinition.operators[0]
  );

  useEffect(() => {
    if (filter.operator && filter.operator !== selectedOperator) {
      setSelectedOperator(filter.operator);
    }
  }, [filter.operator, selectedOperator]);

  const handleSelectClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleOperatorChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newOperator = e.target.value;
    setSelectedOperator(newOperator);
    onOperatorChange(newOperator);
  };

  return (
    <div className={classes.filterChip}>
      <span className={classes.filterName}>{filterDefinition.name}</span>
      <div style={{ position: "relative" }}>
        <select
          className={classes.filterSelect}
          onClick={handleSelectClick}
          value={selectedOperator}
          onChange={handleOperatorChange}
        >
          {filterDefinition.operators.map((op, opIndex) => (
            <option key={opIndex} className={classes.filterOption}>
              {op}
            </option>
          ))}
        </select>
        <Icon icon="caret-down" size={10} className={classes.selectArrow} />
      </div>
      <input
        className={classes.filterInput}
        ref={inputRef}
        value={filter.value}
        onChange={(e) => onChange(e.target.value)}
        onClick={(e) => e.stopPropagation()}
        placeholder="Enter value..."
      />
      <Button
        className={classes.removeFilterButton}
        icon="cross"
        small
        minimal
        onClick={(e) => {
          e.stopPropagation();
          onRemove();
        }}
      />
    </div>
  );
};

export default PlatformTableFilterChip;
