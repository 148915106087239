import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PlatformPowerSearchBar, {
  IFilter,
  IFilterDefinition,
} from "src/common/table/PlatformPowerSearchBar.react";
import PlatformTable, {
  TableOrdering,
} from "src/common/table/PlatformTable.react";
import {
  buildUrlParams,
  calculateMaxPage,
  createOrderingUpdater,
  parseURL,
  updateURL,
} from "src/ui/utils/gridStateUtils";
import { Tariff } from "../../../__generated__/types/Tariff";
import { useFetch } from "../../../common/fetcher/effects";
import PlatformListView from "../../../common/layout/PlatformListView.react";

const DEFAULT_PAGE_SIZE = 15;

interface EllipsisNowrapTextDivProps {
  children: string | undefined;
}

function EllipsisNowrapTextDiv({ children }: EllipsisNowrapTextDivProps) {
  return (
    <div
      style={{
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}
    >
      {children ?? ""}
    </div>
  );
}

export default function PlatformTariffListView() {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [ordering, setOrdering] = useState<TableOrdering[]>([]);
  const [filters, setFilters] = useState<IFilter[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const isUrlUpdate = useRef(false);

  const columns = useMemo(
    () =>
      [
        {
          id: "UNIQUE_NAME",
          header: "Tariff Code",
          sortable: true,
          render: (item: Tariff) => (
            <EllipsisNowrapTextDiv>{item.unique_name}</EllipsisNowrapTextDiv>
          ),
        },
        {
          id: "TARIFF_NAME",
          header: "Tariff name",
          render: (item: Tariff) => (
            <EllipsisNowrapTextDiv>{item.display_name}</EllipsisNowrapTextDiv>
          ),
        },
        {
          id: "DESCRIPTION",
          header: "Description",
          render: (item: Tariff) => (
            <EllipsisNowrapTextDiv>{item.description}</EllipsisNowrapTextDiv>
          ),
        },
      ].map((c) => ({ ...c, href: (item: Tariff) => `/tariffs/${item.id}` })),
    []
  );

  const filterDefs = useMemo<IFilterDefinition[]>(
    () => [
      {
        id: "unique_name",
        name: "Tariff code",
        operators: ["equals", "contains", "starts with", "ends with"],
      },
      {
        id: "display_name",
        name: "Tariff name",
        operators: ["equals", "contains", "starts with", "ends with"],
      },
      {
        id: "description",
        name: "Description",
        operators: ["equals", "contains", "starts with", "ends with"],
      },
    ],
    []
  );

  const parsedURL = useMemo(
    () =>
      parseURL(
        location.search,
        isUrlUpdate,
        navigate,
        filterDefs,
        DEFAULT_PAGE_SIZE
      ),
    [filterDefs, location.search, navigate]
  );

  useEffect(() => {
    const {
      page: newPage,
      pageSize: newPageSize,
      ordering: newOrdering,
      filters: newFilters,
    } = parsedURL;
    isUrlUpdate.current = true;
    setPage(newPage);
    setPageSize(newPageSize);
    setOrdering(newOrdering);
    setFilters(newFilters);
  }, [parsedURL]);

  const [currentUrl, endpointUrl] = useMemo((): [string, string] => {
    const paramString = buildUrlParams(
      page,
      pageSize,
      ordering,
      filters,
      filterDefs
    );
    return [`/tariffs/?${paramString}`, `/api/tariffs/?${paramString}`];
  }, [page, pageSize, ordering, filters, filterDefs]);

  const { data: tariffData, isLoading: tariffDataIsLoading } = useFetch<{
    results: Tariff[];
    count: number;
  }>(endpointUrl);

  useEffect(() => {
    updateURL(currentUrl, navigate, isUrlUpdate);
  }, [currentUrl, navigate]);

  useEffect(() => {
    if (!tariffDataIsLoading && tariffData) {
      const maxPage = calculateMaxPage(tariffData.count, pageSize);
      if (page > maxPage) {
        isUrlUpdate.current = false;
        setPage(1);
      }
    }
  }, [tariffDataIsLoading, tariffData, page, pageSize]);

  const handlePageChange = useCallback((newPage: number) => {
    isUrlUpdate.current = false;
    setPage(newPage);
  }, []);

  const handlePageSizeChange = useCallback((newPageSize: number) => {
    isUrlUpdate.current = false;
    setPageSize(newPageSize);
    setPage(1); // Reset to first page when changing page size
  }, []);

  const handleOrderingChange = useCallback((newOrdering: TableOrdering[]) => {
    isUrlUpdate.current = false;
    setOrdering(createOrderingUpdater(newOrdering));
    setPage(1);
  }, []);

  const handleFilterChange = useCallback((newFilters: IFilter[]) => {
    isUrlUpdate.current = false;
    setFilters(newFilters);
  }, []);

  return (
    <PlatformListView title="Tariffs">
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          paddingTop: "24px",
        }}
      >
        <PlatformPowerSearchBar
          filters={filterDefs}
          handleFilterChange={handleFilterChange}
          initialFilters={filters}
        />
        <PlatformTable<Tariff>
          interactive
          glimmer={tariffDataIsLoading}
          data={tariffData?.results ?? []}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={handlePageSizeChange}
          numberOfAllItems={tariffData?.count ?? 10}
          handlePageChange={handlePageChange}
          currentPage={page}
          ordering={ordering}
          onOrderingChange={handleOrderingChange}
        />
      </div>
    </PlatformListView>
  );
}
