import { Spinner } from "@blueprintjs/core";
import { debounce } from "lodash";
import mapboxgl, { LngLatBounds, Map as MapboxMap, Marker } from "mapbox-gl";
import React, { useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import {
  ClusterProperties,
  ClusterToggleControl,
  createMarkerElement,
  handleClusterClick,
  handleMapChange,
  handleMoveEnd,
  initializeMap,
  MarkerWithRoot,
  spiderifyCluster,
  SuperclusterFeature,
} from "src/ui/utils/mapUtils";
import Supercluster from "supercluster";
import { Battery } from "../../../__generated__/types/Battery";
import { MAPBOX_TOKEN } from "../../barcelonaLocations";
import useResizeObserver from "../useResizeObserver";
import { useDevices } from "./devices";
import PlatformBatteryMarker from "./PlatformBatteryMarker.react";

mapboxgl.accessToken = MAPBOX_TOKEN;

const PADDING = 80;
const MAX_ZOOM = 20;

const useStyles = createUseStyles({
  container: {
    position: "relative",
    width: "100%",
    minWidth: "200px",
    minHeight: "200px",
    "&:focus": {
      outline: "none",
    },
  },
  mapContainer: {
    height: "100%",
    width: "100%",
  },
  spinner: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.05)",
    backdropFilter: "blur(2px)",
    zIndex: 2,
  },
});

interface PlatformSiteDeviceMapViewProps {
  height?: string;
}

const PlatformSiteDeviceMapView: React.FC<PlatformSiteDeviceMapViewProps> = ({
  height = "100vh",
}) => {
  const classes = useStyles();

  const { devices, fetchAllDevices } = useDevices();

  const [loading, setLoading] = useState(true);

  const [zoom, setZoom] = useState<number>(1);
  const [bounds, setBounds] = useState<LngLatBounds | null>(null);
  const [clusters, setClusters] = useState<SuperclusterFeature[]>([]);
  const [supercluster, setSupercluster] = useState<Supercluster | null>(null);
  const [openClusterId, setOpenClusterId] = useState<number | null>(null);

  const clusterToSpiderify = useRef<SuperclusterFeature | null>(null);
  const spiderLegsSourceId = useRef<string>(
    `spider-legs-${Math.random().toString(36).substring(2, 9)}`
  );

  const mapContainer = useRef<HTMLDivElement>(null);
  const dimensions = useResizeObserver(mapContainer);

  const mapRef = useRef<MapboxMap | null>(null);
  const previousZoomRef = useRef<number>(1);

  // Use a native JavaScript Map to manage markers efficiently
  const markersMap = useRef<Map<string, MarkerWithRoot>>(new Map());

  const spiderifiedMarkersRef = useRef<MarkerWithRoot[]>([]);

  const [clusterRadius, setClusterRadius] = useState<number>(0);
  const clusterRadiusRef = useRef<number>(clusterRadius);
  const clusterToggleControlRef = useRef<ClusterToggleControl | null>(null);

  useEffect(() => {
    clusterRadiusRef.current = clusterRadius;
  }, [clusterRadius]);

  // Fetch devices initially and every minute
  useEffect(() => {
    fetchAllDevices();
    const intervalId = setInterval(() => {
      fetchAllDevices();
    }, 60000);

    return () => clearInterval(intervalId);
  }, [fetchAllDevices]);

  // Initialize map
  useEffect(() => {
    if (mapRef.current || !mapContainer.current) {
      return;
    }

    const map = initializeMap({
      container: mapContainer.current,
    });

    mapRef.current = map;

    const onMoveEnd = () => {
      handleMoveEnd(mapRef, setBounds, setZoom, previousZoomRef);
    };

    map.on("moveend", onMoveEnd);
    const clusterToggleControl = new ClusterToggleControl(
      clusterRadiusRef,
      setClusterRadius
    );

    map.addControl(clusterToggleControl, "top-left");

    clusterToggleControlRef.current = clusterToggleControl;

    return () => {
      map.off("moveend", onMoveEnd);
    };
  }, []);

  // Handle map resize
  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.resize();
    }
  }, [dimensions]);

  useEffect(() => {
    if (clusterToggleControlRef.current) {
      clusterToggleControlRef.current.update();
    }
  }, [clusterRadius]);

  // Handle popovers
  useEffect(() => {
    if (mapRef.current) {
      const handlePopovers = () => {
        window.dispatchEvent(new Event("closeAllPopovers"));
      };

      mapRef.current.on("dragstart", handlePopovers);
      mapRef.current.on("zoomstart", handlePopovers);

      return () => {
        if (mapRef.current) {
          mapRef.current.off("dragstart", handlePopovers);
          mapRef.current.on("zoomstart", handlePopovers);
        }
      };
    }
  }, []);

  // Handle map events
  useEffect(() => {
    if (!mapRef.current || !supercluster) {
      return;
    }

    const handleMoveEndEvent = () => {
      if (clusterToSpiderify.current) {
        spiderifyCluster(
          clusterToSpiderify.current,
          supercluster,
          mapRef,
          setOpenClusterId,
          spiderifiedMarkersRef,
          spiderLegsSourceId,
          openClusterId
        );
        clusterToSpiderify.current = null;
      }
    };

    mapRef.current.on("moveend", handleMoveEndEvent);

    return () => {
      if (mapRef.current) {
        mapRef.current.off("moveend", handleMoveEndEvent);
      }
    };
  }, [openClusterId, supercluster]);

  // Handle cluster radius change
  useEffect(() => {
    if (!mapRef.current) {
      return;
    }

    // Remove the immediate marker clearing to prevent flicker
    // Only reset the open cluster state
    setOpenClusterId(null);
  }, [clusterRadius]);

  // Initialize Supercluster when devices load
  useEffect(() => {
    if (!devices) {
      return;
    }

    const points: GeoJSON.Feature<
      GeoJSON.Point,
      ClusterProperties & Partial<Battery>
    >[] = devices
      .filter(
        (device) =>
          device.longitude &&
          device.latitude &&
          !isNaN(parseFloat(device.longitude)) &&
          !isNaN(parseFloat(device.latitude))
      )
      .map((device) => ({
        type: "Feature",
        properties: {
          cluster: false,
          ...device,
        },
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(device.longitude!),
            parseFloat(device.latitude!),
          ],
        },
      }));

    const superclst = new Supercluster({
      radius: clusterRadius,
      maxZoom: MAX_ZOOM,
    });

    superclst.load(points);
    setSupercluster(superclst);

    if (loading && mapRef.current) {
      if (points.length > 0) {
        const bounds = new mapboxgl.LngLatBounds();

        // Extend the bounds to include each point's position
        points.forEach((point) => {
          bounds.extend([
            point.geometry.coordinates[0],
            point.geometry.coordinates[1],
          ]);
        });

        mapRef.current.fitBounds(bounds, {
          padding: PADDING,
          maxZoom: 7,
          animate: false,
        });
      }
      setLoading(false);
    }
  }, [devices, loading, clusterRadius]);

  // Render markers based on clusters
  useEffect(() => {
    if (!mapRef.current) {
      return;
    }

    const existingMarkers = markersMap.current;
    const newMarkers = new Map<string, MarkerWithRoot>();

    clusters.forEach((cluster) => {
      const { geometry, properties } = cluster;

      if (geometry.type !== "Point") {
        // Only handle Point geometries
        return;
      }

      const [lng, lat] = geometry.coordinates as [number, number];
      const key =
        properties.id != null ? String(properties.id) : `${lng}-${lat}`;

      if (existingMarkers.has(key)) {
        newMarkers.set(key, existingMarkers.get(key)!);
        existingMarkers.delete(key);
        return;
      }

      if (properties.cluster && supercluster) {
        // It's a cluster
        const clusterId = properties.cluster_id as number;

        let leaves: any[] = [];
        try {
          leaves = supercluster.getLeaves(clusterId, Infinity);
        } catch (error) {
          // Return as the clusters are no longer valid
          return;
        }

        const batteries = leaves.map((leaf) => leaf.properties as Battery);

        const { element, root } = createMarkerElement(
          <PlatformBatteryMarker
            batteries={batteries}
            isCluster={true}
            pointCount={properties.point_count ?? 0}
          />
        );

        const marker = new Marker(element)
          .setLngLat([lng, lat])
          .addTo(mapRef.current!);

        marker.getElement().addEventListener("click", () => {
          handleClusterClick(
            cluster,
            supercluster,
            mapRef,
            openClusterId,
            setOpenClusterId,
            spiderifiedMarkersRef,
            spiderLegsSourceId,
            clusterToSpiderify
          );
        });

        newMarkers.set(key, { marker, root });
      } else {
        // It's an individual point
        const batteryData = properties as Battery;

        const { element, root } = createMarkerElement(
          <PlatformBatteryMarker batteries={[batteryData]} />
        );
        element.className = "marker";

        const marker = new Marker(element)
          .setLngLat([lng, lat])
          .addTo(mapRef.current!);

        newMarkers.set(key, { marker, root });
      }
    });

    // Remove markers that are no longer needed with fade-out
    existingMarkers.forEach(({ marker, root }: MarkerWithRoot) => {
      // Apply fade-out transition
      marker.getElement().style.opacity = "0";

      // Wait for the transition to complete before removing
      setTimeout(() => {
        marker.remove();
        root.unmount();
      }, 1);
    });

    // Update the markers map
    markersMap.current = newMarkers;
  }, [clusters, supercluster, openClusterId]);

  // Update clusters when Supercluster, bounds, or zoom changes with debounced updates
  useEffect(() => {
    if (!supercluster || !bounds) {
      return;
    }

    const bbox: [number, number, number, number] = [
      bounds.getWest(),
      bounds.getSouth(),
      bounds.getEast(),
      bounds.getNorth(),
    ];

    const currentZoom = Math.floor(zoom);

    const updateClusters = () => {
      const clustered = supercluster.getClusters(bbox, currentZoom);
      setClusters(clustered as SuperclusterFeature[]);
    };

    const debouncedUpdateClusters = debounce(updateClusters, 100);
    debouncedUpdateClusters();

    return () => {
      debouncedUpdateClusters.cancel();
    };
  }, [supercluster, bounds, zoom]);

  // Clear spiderified markers and legs when map is zoomed out
  useEffect(() => {
    if (!mapRef.current) {
      return;
    }

    const cleanupHandleMoveEnd = () => {
      handleMoveEnd(mapRef, setBounds, setZoom, previousZoomRef);
    };

    const cleanupHandleMapChange = handleMapChange(
      mapRef,
      spiderifiedMarkersRef,
      spiderLegsSourceId,
      setOpenClusterId,
      previousZoomRef
    );

    mapRef.current.on("moveend", cleanupHandleMoveEnd);

    return () => {
      if (mapRef.current) {
        mapRef.current.off("moveend", cleanupHandleMoveEnd);
      }
      cleanupHandleMapChange();
    };
  }, [spiderLegsSourceId]);

  return (
    <div
      className={classes.container}
      style={{
        height,
      }}
    >
      <div ref={mapContainer} className={classes.mapContainer}></div>
      {loading && (
        <div className={classes.spinner}>
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default PlatformSiteDeviceMapView;
