import {
  AnchorButton,
  Button,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
} from "@blueprintjs/core";
import { mdiHomeBattery } from "@mdi/js";
import Icon from "@mdi/react";
import { Campaign } from "@mui/icons-material";
import { useMemo, useState } from "react";
import { createUseStyles } from "react-jss";
import { useLocation, useNavigate } from "react-router-dom";
import { BACKGROUND_COLOR } from "../../sites/platform/PlatformSiteBackground.react";
import { useAuthentication } from "../../sites/platform/common/authentication/authentication";
import LogoText from "../logo/LogoText.react";
import SpinningLogo from "../logo/SpinningLogo.react";

import { CiHardDrive } from "react-icons/ci";
import { GateKeeper } from "../../gk/__generated__/gatekeepers";
import useGatekeeper from "../../gk/useGatekeeper";
import { useEnterpriseLabels } from "../../sites/platform/enterpriseLabels/useEnterpriseLabels";

const WIDTH = "240px";
const WIDTH_COLLAPSED = "86px";
// Define JSS styles
const useStyles = createUseStyles({
  navigationBar: {
    position: "fixed",
    width: WIDTH,
    left: 0,
    paddingTop: 12,
    top: 0,
    bottom: 0,
    backgroundColor: BACKGROUND_COLOR,

    borderRight: "2px solid #2F343C",
    display: "flex",
    flexDirection: "column",
  },
  navigationBarCollapsed: {
    width: WIDTH_COLLAPSED,
  },
  logoSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 12,
    marginRight: 12,
    justifyContent: "space-between",
  },
  logoContainer: {
    display: "flex",
    flexDirection: "row",
    height: "20px",
  },
  menuContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
  },
  spacer: {
    width: 12,
  },
  // Add more styles as needed
});

export default function PlatformVerticalNavigationBar() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const canUseAssistant = useGatekeeper(GateKeeper.CAN_USE_ASSISTANT);
  const canUseBuilder = useGatekeeper(GateKeeper.CAN_USE_BUILDER);
  const canUseCanvas = useGatekeeper(GateKeeper.CAN_USE_CANVAS);
  const canUseComposer = useGatekeeper(GateKeeper.CAN_USE_COMPOSER);
  const canUseCampaigns = useGatekeeper(GateKeeper.CAN_USE_CAMPAIGNS);
  const canUseDataStudio = useGatekeeper(GateKeeper.CAN_USE_DATA_STUDIO);
  const canUseDataDrive = useGatekeeper(GateKeeper.CAN_USE_DATA_DRIVE);
  const canUseDatalayerPage = useGatekeeper(GateKeeper.CAN_USE_DATALAYER_PAGE);

  const [collapsed, setCollapsed] = useState(false);

  const { logout } = useAuthentication();

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const { enterpriseLabels, selectedLabel, handleSetSelectedLabel } =
    useEnterpriseLabels();

  const isSelected = (path: string) => location.pathname === path;

  const selectedEnterpriseLabelLogo = useMemo(() => {
    const filteredLabels = enterpriseLabels.filter(
      (el) => el.name === selectedLabel
    );

    if (filteredLabels.length > 0) {
      return filteredLabels[0].logo ?? null;
    }

    return null;
  }, [enterpriseLabels, selectedLabel]);

  return (
    <>
      <div
        className={`${classes.navigationBar} ${
          collapsed ? classes.navigationBarCollapsed : null
        }`}
      >
        <div className={classes.logoSection}>
          <div className={classes.logoContainer}>
            <SpinningLogo verticalAlign="text-bottom" disableSpinning />
            <div className={classes.spacer} />
            {collapsed ? null : <LogoText disableAnimation />}
          </div>
          <AnchorButton
            icon={collapsed ? "menu-open" : "menu-closed"}
            minimal
            large
            onClick={() => setCollapsed((prev) => !prev)}
          />
        </div>
        {collapsed ? null : selectedEnterpriseLabelLogo ? (
          <div
            style={{
              paddingLeft: "12px",
              paddingRight: "68px",
            }}
            className="w-full max-w-full overflow-hidden"
          >
            <img
              src={selectedEnterpriseLabelLogo}
              alt="EVEON Logo"
              style={{ width: "100%", height: "auto", display: "block" }}
            />
          </div>
        ) : null}

        <div className={classes.menuContainer}>
          <Menu
            style={{
              backgroundColor: "inherit",
              width: `calc(WIDTH_COLLAPSED - 12)`,
              minWidth: 0,
            }}
            large
          >
            <MenuDivider />
            <Button
              minimal
              fill
              icon="home"
              alignText="left"
              text={collapsed ? null : "Home"}
              onClick={() => navigate("/")}
              active={isSelected("/")}
            />
            <Button
              minimal
              fill
              icon="people"
              alignText="left"
              text={collapsed ? null : "Customers"}
              onClick={() => navigate("/customers/")}
              active={isSelected("/customers/")}
            />
            <Button
              minimal
              fill
              alignText="left"
              icon={
                <Icon
                  path={mdiHomeBattery}
                  size={0.9}
                  color={"#ABB3BF"}
                  style={{ marginLeft: -4 }}
                />
              }
              text={collapsed ? null : "Devices"}
              onClick={() => navigate("/devices/")}
              active={isSelected("/devices/")}
            />
            <Button
              minimal
              fill
              alignText="left"
              icon="office"
              text={collapsed ? null : "Properties"}
              onClick={() => navigate(`/properties/`)}
              active={isSelected("/properties/")}
            />
            {canUseDataStudio ||
            canUseCampaigns ||
            canUseComposer ||
            canUseCanvas ||
            canUseBuilder ||
            canUseAssistant ? (
              <MenuDivider />
            ) : null}
            {canUseDataStudio === true && (
              <Button
                minimal
                alignText="left"
                fill
                icon="style"
                text={collapsed ? null : "Data Studio"}
                onClick={() => navigate(`/studio/`)}
                active={isSelected("/studio/")}
              />
            )}
            {canUseCampaigns === true && (
              <Button
                minimal
                alignText="left"
                fill
                icon={
                  <Campaign
                    style={{ fontSize: 19, marginRight: 7, color: "#ABB3BF" }}
                  />
                }
                text={collapsed ? null : "Campaigns"}
                onClick={() => navigate(`/campaigns/`)}
                active={isSelected("/campaigns/")}
              />
            )}
            {canUseComposer === true && (
              <Button
                minimal
                alignText="left"
                fill
                icon="annotation"
                text={collapsed ? null : "Composer"}
                onClick={() => navigate(`/composer/`)}
                active={isSelected("/composer/")}
              />
            )}
            {canUseCanvas === true && (
              <Button
                minimal
                alignText="left"
                fill
                icon="control"
                text={collapsed ? null : "Canvas"}
                onClick={() => navigate(`/canvas/`)}
                active={isSelected("/canvas/")}
              />
            )}
            {canUseBuilder === true && (
              <Button
                minimal
                alignText="left"
                fill
                icon="code"
                text={collapsed ? null : "Builder"}
                onClick={() => navigate(`/builder/`)}
                active={isSelected("/builder/")}
              />
            )}
            {canUseBuilder === true && (
              <Button
                minimal
                alignText="left"
                fill
                icon="lab-test"
                text={collapsed ? null : "Experiments"}
                onClick={() => navigate(`/lab/`)}
                active={isSelected("/lab/")}
              />
            )}
            {canUseAssistant === true && (
              <Button
                minimal
                alignText="left"
                fill
                icon="chat"
                text={collapsed ? null : "Assistant"}
                onClick={() => navigate(`/assistant/`)}
                active={isSelected("/assistant/")}
              />
            )}
          </Menu>

          <Menu
            style={{
              backgroundColor: "inherit",
              width: `calc(WIDTH_COLLAPSED - 12)`,

              minWidth: 0,
            }}
            large
          >
            {/*<AnchorButton
              href="/assistant/"
              minimal
              alignText="left"
              fill
              disabled
              icon="layout-auto"
              text={collapsed ? null : "Virtual Power Plant"}
              onClick={() => navigate(`/vpp/`)}
              active={isSelected("/vpp/")}
          />*/}

            {canUseDataDrive ? (
              <Button
                minimal
                alignText="left"
                fill
                icon={
                  <CiHardDrive
                    style={{
                      fontSize: 19,

                      color: "#ABB3BF",
                    }}
                  />
                }
                text={collapsed ? null : "Drive"}
                onClick={() => navigate(`/drive/`)}
                active={isSelected("/drive/")}
              />
            ) : null}

            {canUseDatalayerPage ? (
              <Button
                minimal
                alignText="left"
                fill
                icon="data-lineage"
                text={collapsed ? null : "Datalayer"}
                onClick={() => navigate(`/datalayer/`)}
                active={isSelected("/datalayer/")}
              />
            ) : null}
            <MenuDivider />

            <Button
              minimal
              fill
              icon="cog"
              alignText="left"
              text={collapsed ? null : "Settings"}
              onClick={() => navigate("/settings/")}
              active={isSelected("/settings/")}
            />
            {enterpriseLabels.length > 1 ? (
              <Popover
                fill
                autoFocus={false}
                content={
                  <Menu large>
                    {enterpriseLabels.map((el) => (
                      <MenuItem
                        onClick={() => {
                          handleSetSelectedLabel(
                            el.name,
                            enterpriseLabels.filter(
                              (e) => e.name === el.name
                            )[0]
                          );
                        }}
                        key={el.name}
                        text={el.name.toUpperCase()}
                        active={selectedLabel === el.name}
                      />
                    ))}
                  </Menu>
                }
                placement="right-end"
              >
                <Button
                  style={{ width: "100%" }}
                  minimal
                  fill
                  icon="office"
                  alignText="left"
                  rightIcon="caret-right"
                  text={selectedLabel ? selectedLabel.toUpperCase() : "N/A"}
                />
              </Popover>
            ) : null}
            <Button
              minimal
              fill
              intent="danger"
              icon="log-out"
              alignText="left"
              text={collapsed ? null : "Logout"}
              onClick={handleLogout}
              active={isSelected("/logout/")}
            />
          </Menu>
        </div>
      </div>
      <div
        style={{
          width: collapsed ? WIDTH_COLLAPSED : WIDTH,
          height: "100vh",
          backgroundColor: "inherit",
          left: 0,
        }}
      />
    </>
  );
}
