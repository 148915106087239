import { Button, Tooltip } from "@blueprintjs/core";
import React, { useMemo } from "react";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import capitalizeName from "./capitalizeName";
import { useCustomer } from "./customers";

interface PlatformSiteCustomerClickableLabelProps {
  customerId: string;
}

const useStyles = createUseStyles({
  button: {
    borderRadius: 6,
    justifyContent: "flex-start",
    "& span": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: "100%",
    },
  },
  tooltip: {
    fontFamily: "Barlow",
  },
});

export default function PlatformSiteCustomerClickableLabel({
  customerId,
}: PlatformSiteCustomerClickableLabelProps) {
  const classes = useStyles();

  const navigate = useNavigate();

  const customer = useCustomer(parseInt(customerId));

  const customerFullName = useMemo(() => {
    if (customer?.first_name && customer?.last_name) {
      return capitalizeName(`${customer.first_name} ${customer.last_name}`);
    }
    return null;
  }, [customer]);

  if (customer === null) {
    return null;
  }

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/customers/${customerId}`);
  };

  return (
      <Tooltip
        fill
        content={<span className={classes.tooltip}>{customerFullName}</span>}
      >
        <Button
          fill
          className={classes.button}
          onClick={handleClick}
          outlined
          icon="person"
        >
          {customerFullName}
        </Button>
      </Tooltip>
  );
}
