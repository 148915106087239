import { IconSize } from "@blueprintjs/icons";
import { Battery } from "@ec1/types/Battery";
import { useMemo } from "react";
import { useBrands } from "src/core/CoreDataContext";
import EllipsisNowrapTextDiv from "./EllipsisNowrapTextdivProps.react";

interface BatteryBrandNameTagProps {
  battery: Battery;
}
export default function BatteryBrandNameTag({
  battery,
}: BatteryBrandNameTagProps) {
  const brands = useBrands();

  const brand = useMemo(() => {
    if (brands.map((b) => b.id).includes(battery.associated_brand)) {
      return brands.filter((b) => b.id === battery.associated_brand)[0];
    }
    return null;
  }, [brands, battery]);

  if (brand) {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {brand && (
          <img
            style={{ marginRight: "8px" }}
            alt="brand-icon"
            src={brand.icon}
            height={IconSize.STANDARD}
          />
        )}
        <EllipsisNowrapTextDiv>
          {brand?.display_name ?? brand.name}
        </EllipsisNowrapTextDiv>
      </div>
    );
  }

  return <div>{"-"}</div>;
}
