import {
  PiBatteryVerticalEmptyDuotone,
  PiBatteryVerticalFullDuotone,
  PiBatteryVerticalHighDuotone,
  PiBatteryVerticalLowDuotone,
  PiBatteryVerticalMediumDuotone,
  PiBatteryWarningVerticalDuotone,
} from "react-icons/pi";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  /* Uncomment for animation */
  // "@keyframes easeBlink": {
  //   "0%, 100%": {
  //     opacity: 1,
  //   },
  //   "50%": {
  //     opacity: 0.6,
  //   },
  // },
  // animated: {
  //   animationName: "$easeBlink",
  //   animationDuration: "1s",
  //   animationIterationCount: "infinite",
  //   animationTimingFunction: "ease-in-out",
  // },
  icon: {
    margin: "-2px -8px",
    padding: 0,
  },
});

export function getBatteryIconColor(
  batteryLevel: number,
  stationState: string | undefined
): string {
  if (stationState === "FAULT") {
    return "#ba3636";
  }

  if (stationState === "OFFLINE") {
    return "#999";
  }

  if (batteryLevel >= 60) {
    return "#29A634";
  }

  if (batteryLevel >= 20) {
    return "#EC9A3C";
  }

  return "#ec563c";
}

interface BatteryIconProps {
  batteryLevel: number;
  stationState?: string;
  fill?: boolean;
}

export function BatteryIcon({
  batteryLevel,
  stationState,
  fill,
}: BatteryIconProps) {
  const classes = useStyles();
  const color = getBatteryIconColor(batteryLevel, stationState);
  const iconClass = classes.icon;
  /* isFault
    ? `${classes.icon} ${classes.animated}`
    : classes.icon; */

  const renderIcon = () => {
    if (stationState === "FAULT") {
      return (
        <PiBatteryWarningVerticalDuotone className={iconClass} color={color} />
      );
    }

    if (stationState === "OFFLINE") {
      return (
        <PiBatteryWarningVerticalDuotone className={iconClass} color={color} />
      );
    }

    if (batteryLevel === 100) {
      return (
        <PiBatteryVerticalFullDuotone className={iconClass} color={color} />
      );
    }

    if (batteryLevel >= 80) {
      return (
        <PiBatteryVerticalHighDuotone className={iconClass} color={color} />
      );
    }

    if (batteryLevel >= 30) {
      return (
        <PiBatteryVerticalMediumDuotone className={iconClass} color={color} />
      );
    }

    if (batteryLevel > 0) {
      return (
        <PiBatteryVerticalLowDuotone className={iconClass} color={color} />
      );
    }

    if (fill) {
      return (
        <PiBatteryVerticalEmptyDuotone
          className={iconClass}
          color="white"
          fill="white"
        />
      );
    }

    return (
      <PiBatteryVerticalEmptyDuotone className={iconClass} color={color} />
    );
  };

  return renderIcon();
}
