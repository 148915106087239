import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useFetch } from "src/common/fetcher/effects";

import { CSVDataset } from "@ec1/types/CSVDataset";
export default function PlatformFilesystem() {
  const fetchAllUrl = useMemo(() => {
    return "/api/datalayer/csv-dataset/";
  }, []);

  const { data } = useFetch<{ results: CSVDataset[] }>(fetchAllUrl);

  const navigate = useNavigate();

  return (
    <div style={{ padding: "24px" }}>
      <div>DATASETS</div>
      {data?.results.map((item) => (
        <div
          key={item.uuid}
          style={{ background: "gray", marginTop: "8px", cursor: "pointer" }}
          onClick={() => navigate(`/drive/${item.uuid}`)}
        >
          {item.display_name}
        </div>
      ))}
    </div>
  );
}

// const DynamicTable = ({ data }: { data: any }) => {
//   if (!data || data.length === 0) {
//     return <div>No data to display</div>;
//   }

//   const columns = Object.keys(data[0]);

//   const cellRenderer = (columnKey: string) => (rowIndex: number) => {
//     return <Cell>{String(data[rowIndex][columnKey])}</Cell>;
//   };

//   return (
//     <Table2 numRows={data.length}>
//       {columns.map((columnKey) => (
//         <Column
//           key={columnKey}
//           name={columnKey}
//           cellRenderer={cellRenderer(columnKey)}
//         />
//       ))}
//     </Table2>
//   );
// };
