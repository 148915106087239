import { Popover } from "@blueprintjs/core";
import React, { useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { BatteryIcon } from "src/ui/utils/batteryIcons";
import { Battery } from "../../../__generated__/types/Battery";
import PlatformBatteryClusterPopoverContent from "./PlatformBatteryClusterPopoverContent.react";
import PlatformBatteryPopoverContent from "./PlatformBatteryPopoverContent.react";

export interface PlatformBatteryMarkerProps {
  batteries: Battery[];
  isCluster?: boolean;
  pointCount?: number;
}

const useStyles = createUseStyles({
  container: {
    position: "relative",
    width: "40px",
    height: "40px",
    backgroundColor: "rgba(103, 111, 120, 0.7)",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  iconContainer: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    textAlign: "center",
    marginBottom: "35%",
  },
  firstIcon: {
    fontSize: "12px",
    transform: "translate(-3px, -3px)",
    zIndex: 1,
    background: "blue",
  },
  secondIcon: {
    fontSize: "12px",
    transform: "translate(3px, 3px)",
    zIndex: 2,
  },
  count: {
    position: "absolute",
    top: "75%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: "12px",
    color: "#fff",
    fontWeight: "500",
    fontFamily: "Barlow",
    letterSpacing: 1,
    textShadow: "1px 1px 2px #444",
    marginLeft: 1,
  },
  marker: {
    width: "14px",
    height: "18px",
    backgroundColor: "black",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    fontSize: "24px",
    cursor: "pointer",
  },
  badge: {
    position: "absolute",
    width: "16px",
    height: "16px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    fontSize: "10px",
    fontWeight: 500,
    boxShadow: "0 0 2px rgba(0,0,0,0.5)",
    textShadow: "1px 1px 2px #444",
    fontFamily: "Barlow",
  },
  topLeftBadge: {
    top: "-2px",
    left: "-6px",
    backgroundColor: "#b87121",
  },
  topRightBadge: {
    top: "-2px",
    right: "-6px",
    backgroundColor: "#c20a0a",
  },
});

const PlatformBatteryMarker: React.FC<PlatformBatteryMarkerProps> = ({
  batteries,
  isCluster = false,
  pointCount = 1,
}) => {
  const classes = useStyles();

  const [isPopoverOpen, setPopoverOpen] = useState(false);

  const openTimerRef = useRef<number | null>(null);
  const closeTimerRef = useRef<number | null>(null);

  // Hover delay durations in milliseconds
  const HOVER_OPEN_DELAY = 200;
  const HOVER_CLOSE_DELAY = 400;

  useEffect(() => {
    const handleCloseAllPopovers = () => {
      if (openTimerRef.current) {
        clearTimeout(openTimerRef.current);
        openTimerRef.current = null;
      }
      if (closeTimerRef.current) {
        clearTimeout(closeTimerRef.current);
        closeTimerRef.current = null;
      }
      setPopoverOpen(false);
    };

    window.addEventListener("closeAllPopovers", handleCloseAllPopovers);

    return () => {
      window.removeEventListener("closeAllPopovers", handleCloseAllPopovers);
      if (openTimerRef.current) {
        clearTimeout(openTimerRef.current);
      }
      if (closeTimerRef.current) {
        clearTimeout(closeTimerRef.current);
      }
    };
  }, []);

  const handleMouseEnter = () => {
    // If there's a close timer, cancel it
    if (closeTimerRef.current) {
      clearTimeout(closeTimerRef.current);
      closeTimerRef.current = null;
    }

    // Start a timer to open the popover after HOVER_OPEN_DELAY
    openTimerRef.current = window.setTimeout(() => {
      setPopoverOpen(true);
      openTimerRef.current = null;
    }, HOVER_OPEN_DELAY);
  };

  const handleMouseLeave = () => {
    // If there's an open timer, cancel it
    if (openTimerRef.current) {
      clearTimeout(openTimerRef.current);
      openTimerRef.current = null;
    }

    // Start a timer to close the popover after HOVER_CLOSE_DELAY
    closeTimerRef.current = window.setTimeout(() => {
      setPopoverOpen(false);
      closeTimerRef.current = null;
    }, HOVER_CLOSE_DELAY);
  };

  const handleContentMouseEnter = () => {
    // If there's a close timer, cancel it
    if (closeTimerRef.current) {
      clearTimeout(closeTimerRef.current);
      closeTimerRef.current = null;
    }
    setPopoverOpen(true);
  };

  const handleContentMouseLeave = () => {
    // Start a timer to close the popover after HOVER_CLOSE_DELAY
    closeTimerRef.current = window.setTimeout(() => {
      setPopoverOpen(false);
      closeTimerRef.current = null;
    }, HOVER_CLOSE_DELAY);
  };

  if (isCluster) {
    const faultCount = batteries.filter(
      (battery) => battery.station_state === "FAULT"
    ).length;
    const offlineCount = batteries.filter(
      (battery) => battery.station_state === "OFFLINE"
    ).length;

    return (
      <Popover
        isOpen={isPopoverOpen}
        onInteraction={(nextOpen) => setPopoverOpen(nextOpen)}
        position="top"
        content={
          <div
            onMouseEnter={handleContentMouseEnter}
            onMouseLeave={handleContentMouseLeave}
          >
            <PlatformBatteryClusterPopoverContent batteries={batteries} />
          </div>
        }
        autoFocus={false}
        enforceFocus={false}
        captureDismiss={true}
      >
        <div
          className={classes.container}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className={classes.iconContainer}>
            <div className={classes.firstIcon}>
              <BatteryIcon
                batteryLevel={0}
                stationState={undefined}
                fill={true}
              />
            </div>
            <div className={classes.secondIcon}>
              <BatteryIcon
                batteryLevel={0}
                stationState={undefined}
                fill={true}
              />
            </div>
          </div>
          {pointCount && <div className={classes.count}>{pointCount}</div>}
          {offlineCount > 0 && (
            <div className={`${classes.badge} ${classes.topLeftBadge}`}>
              {offlineCount}
            </div>
          )}
          {faultCount > 0 && (
            <div className={`${classes.badge} ${classes.topRightBadge}`}>
              {faultCount}
            </div>
          )}
        </div>
      </Popover>
    );
  }

  if (batteries.length === 0) {
    return null;
  }

  return (
    <Popover
      isOpen={isPopoverOpen}
      onInteraction={(nextOpen) => setPopoverOpen(nextOpen)}
      position="top"
      content={
        <div
          onMouseEnter={handleContentMouseEnter}
          onMouseLeave={handleContentMouseLeave}
        >
          <PlatformBatteryPopoverContent battery={batteries[0]} />
        </div>
      }
      autoFocus={false}
      enforceFocus={false}
      captureDismiss={true}
    >
      <div
        className={classes.marker}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <BatteryIcon
          batteryLevel={batteries[0].battery_level ?? 0}
          stationState={batteries[0].station_state}
        />
      </div>
    </Popover>
  );
};

export default PlatformBatteryMarker;
