import { Button, Spinner } from "@blueprintjs/core";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { GridColDef } from "@mui/x-data-grid";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PlatformProperty } from "../../../__generated__/types/PlatformProperty";
import Ec1DataGrid from "../../../ui/components/Ec1DataGrid.react";
import PlatformSiteCustomerClickableLabel from "../customers/PlatformSiteCustomerClickableLabel.react";
import PlatformPropertyDialog from "./PlatformPropertyDialog.react";
import { useProperties } from "./properties";

export default function PlatformSitePropertyListViewPage() {
  const { fetchProperties } = useProperties();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [gridKey, setGridKey] = useState<number>(0);

  const navigate = useNavigate();

  const handleActionClick = useCallback(
    (propertyId: string) => {
      navigate(`/properties/${propertyId}`);
    },
    [navigate]
  );

  const handleContextMenuItemClick = useCallback(
    (action: string, id: string | null) => {
      if (action === "view" && id) {
        handleActionClick(id);
      }
    },
    [handleActionClick]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      { field: "property_uid", headerName: "Property UID", flex: 2 },
      {
        field: "customer",
        headerName: "Customer",
        flex: 2,
        minWidth: 180,
        renderCell: (params) => (
          <PlatformSiteCustomerClickableLabel customerId={params.value} />
        ),
      },
    ],
    []
  );

  const contextMenuOptions = useMemo(
    () => [
      {
        label: "View Property",
        icon: <VisibilityIcon />,
        action: (id: string | null) => handleContextMenuItemClick("view", id),
      },
    ],
    [handleContextMenuItemClick]
  );

  const refreshPropertyList = useCallback(() => {
    setIsDialogOpen(false);
    setGridKey((prevKey) => prevKey + 1);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          width: "100%",
          overflow: "hidden",
          padding: "24px 24px 38px 24px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            fontFamily: "Barlow",
            letterSpacing: 1.5,
            fontWeight: 700,
            fontSize: 24,
            color: "#D3D8DE",
            marginBottom: 24,
          }}
        >
          <div>Properties</div>
          <div style={{ marginTop: "12px" }}>
            <Button
              minimal
              outlined
              rightIcon={dialogLoading ? <Spinner size={16} /> : "add"}
              onClick={() => {
                setIsDialogOpen(true);
              }}
            >
              Add new Property
            </Button>
            <PlatformPropertyDialog
              isEdit={false}
              isOpen={isDialogOpen}
              onClose={() => setIsDialogOpen(false)}
              onEnrollmentSuccess={refreshPropertyList}
              setIsLoadingParent={setDialogLoading}
            />
          </div>
        </div>
        <Ec1DataGrid<PlatformProperty>
          id="gridProperties"
          key={gridKey}
          get={fetchProperties}
          columns={columns}
          contextMenuOptions={contextMenuOptions}
          getRowId={(row: PlatformProperty) => row.id ?? "0"}
          onRowClick={(property, event) => {
            handleActionClick(String(property.id));
          }}
        />
      </div>
    </div>
  );
}
