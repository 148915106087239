import { useAuthHeaders } from "@ec1/authentication/authentication";
import { useCallback, useEffect, useRef, useState } from "react";

interface FetchAllOptions {
  authenticated?: boolean;
  method?: "GET";
  useCache?: boolean;
  pollingInterval?: number;
}

interface PaginatedResponse<T> {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
}

export function useFetchAll<T>(
  initialUrl: string | null,
  options: FetchAllOptions = {}
): {
  data: T[] | null;
  isLoading: boolean;
  error: Error | null;
  refetch: () => void;
} {
  const {
    authenticated = true,
    method = "GET",
    useCache = false,
    pollingInterval = 0,
  } = options;
  const authHeaders = useAuthHeaders();

  const [data, setData] = useState<T[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const abortControllerRef = useRef<AbortController | null>(null);
  const pollingIntervalRef = useRef<number | null>(null);

  const fetchAllData = useCallback(async () => {
    if (!initialUrl) {
      setData(null);
      return;
    }

    // Abort previous request if it exists
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController for this fetch request
    abortControllerRef.current = new AbortController();

    setIsLoading(true);
    setError(null);

    let allResults: T[] = [];
    let nextUrl: string | null = initialUrl;

    try {
      while (nextUrl !== null) {
        // Check cache if enabled
        if (useCache) {
          const cachedData = localStorage.getItem(nextUrl);
          if (cachedData) {
            const parsedData = JSON.parse(cachedData) as PaginatedResponse<T>;
            allResults = [...allResults, ...parsedData.results];
            nextUrl = parsedData.next;
            continue;
          }
        }

        const response = await fetch(nextUrl, {
          method,
          headers: authenticated
            ? authHeaders
            : { "Content-Type": "application/json" },
          signal: abortControllerRef.current.signal,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result: PaginatedResponse<T> = await response.json();
        allResults = [...allResults, ...result.results];
        nextUrl = result.next;

        // Cache the result if caching is enabled
        if (useCache && nextUrl) {
          localStorage.setItem(nextUrl, JSON.stringify(result));
        }
      }

      setData(allResults);
      setIsLoading(false);
    } catch (e) {
      if (e instanceof Error && e.name === "AbortError") {
        // Ignore abort errors
        return;
      }
      setError(e instanceof Error ? e : new Error("An unknown error occurred"));
      setData(null);
      setIsLoading(false);
    }
  }, [initialUrl, method, authenticated, authHeaders, useCache]);

  useEffect(() => {
    fetchAllData();

    // Set up polling if interval is greater than 0
    if (pollingInterval > 0) {
      pollingIntervalRef.current = window.setInterval(
        fetchAllData,
        pollingInterval
      );
    }

    return () => {
      // Abort the fetch if the component unmounts or dependencies change
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      // Clear the polling interval
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
      }
    };
  }, [fetchAllData, pollingInterval]);

  return { data, isLoading, error, refetch: fetchAllData };
}
