import { Button, ButtonGroup, Intent, MenuItem } from "@blueprintjs/core";
import { ItemPredicate, ItemRenderer, Select } from "@blueprintjs/select";
import { useEffect, useMemo, useState } from "react";
import { createUseStyles } from "react-jss";
import { Ec1Toaster } from "../../../ui/components/Ec1Toaster.react";
import { useDevices } from "../devices/devices";
import { useProperties } from "../properties/properties";
import capitalizeName from "./capitalizeName";
import { Customer, useCustomers } from "./customers";

interface PlatformCustomerAssignWidgetProps {
  deviceId?: number;
  propertyId?: number;
  customerId?: number | null;
  onCustomerSelect?: (customerId: number | null) => void;
}

const useStyles = createUseStyles({
  button: {
    width: 240,
    outline: "none",
  },
  label: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontFamily: "Barlow",
    paddingLeft: 2,
  },
  labelEmpty: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontFamily: "Barlow",
    opacity: 0.3,
  },
  iconButton: {
    outline: "none",
  },
});

const showToast = (
  message: string,
  intent: Intent,
  buttonText?: string,
  buttonOnClick?: () => void
) => {
  Ec1Toaster.show({
    timeout: 5000,
    message: (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: 18,
            fontFamily: "Barlow",
          }}
        >
          <div style={{ marginRight: 15, height: 20 }}>{message}</div>
          {buttonText && (
            <Button
              intent="primary"
              text={buttonText}
              onClick={buttonOnClick}
              style={{
                height: 20,
                padding: "0 5px",
                minHeight: "unset",
                minWidth: "unset",
                fontSize: 12,
              }}
            />
          )}
        </div>
      </>
    ),
    intent: intent,
  });
};

const filterCustomer: ItemPredicate<Customer> = (
  query,
  customer,
  _index,
  exactMatch
) => {
  const fullName = capitalizeName(
    `${customer.first_name} ${customer.last_name}`
  );
  const normalizedName = fullName.toLowerCase();
  const normalizedQuery = query.trim().toLowerCase();

  if (exactMatch) {
    return normalizedName === normalizedQuery;
  }
  return normalizedName.includes(normalizedQuery);
};

const renderCustomer: ItemRenderer<Customer> = (
  customer,
  { handleClick, handleFocus, modifiers }
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  return (
    <MenuItem
      style={{ paddingLeft: 30, fontFamily: "Barlow" }}
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={customer.id}
      onClick={handleClick}
      onFocus={handleFocus}
      roleStructure="listoption"
      text={capitalizeName(`${customer.first_name} ${customer.last_name}`)}
    />
  );
};

export default function PlatformCustomerAssignWidget({
  deviceId,
  propertyId,
  customerId,
  onCustomerSelect,
}: PlatformCustomerAssignWidgetProps) {
  const classes = useStyles();

  const { customers } = useCustomers();
  const { assignCustomerToDevice } = useDevices();
  const { assignCustomerToProperty } = useProperties();

  const sortedCustomers = useMemo(() => {
    return [...customers].sort((a, b) => {
      const nameA = capitalizeName(
        `${a.first_name} ${a.last_name}`
      ).toLowerCase();
      const nameB = capitalizeName(
        `${b.first_name} ${b.last_name}`
      ).toLowerCase();
      return nameA.localeCompare(nameB);
    });
  }, [customers]);

  let assignedCustomer: Customer | null = null;
  if (customerId && sortedCustomers.length) {
    const filteredCustomers = sortedCustomers.filter(
      (c) => c.id === customerId
    );
    if (filteredCustomers.length === 1) {
      assignedCustomer = filteredCustomers[0];
    }
  }

  const [loading, setLoading] = useState(true);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(
    assignedCustomer
  );

  useEffect(() => {
    if (customerId) {
      setLoading(true);
      if (assignedCustomer) {
        setSelectedCustomer(assignedCustomer);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [assignedCustomer, customerId]);

  const customerFullName = useMemo(() => {
    if (
      selectedCustomer &&
      selectedCustomer?.first_name &&
      selectedCustomer?.last_name
    ) {
      return capitalizeName(
        `${selectedCustomer.first_name} ${selectedCustomer.last_name}`
      );
    }
    return null;
  }, [selectedCustomer]);

  const handleCustomerAssign = async (customer: Customer) => {
    setLoading(true);
    if (customer.id) {
      let response = deviceId
        ? await assignCustomerToDevice(deviceId, customer.id)
        : propertyId
        ? await assignCustomerToProperty(propertyId, customer.id)
        : null;
      if (response) {
        if (response.success) {
          setSelectedCustomer(customer);
          onCustomerSelect?.(customer.id);
        } else {
          showToast(
            "Customer unassignment failed. Please try again.",
            Intent.DANGER
          );
        }
      }
    }
    setLoading(false);
  };

  const handleClearSelection = async () => {
    setLoading(true);
    let response = deviceId
      ? await assignCustomerToDevice(deviceId, null)
      : propertyId
      ? await assignCustomerToProperty(propertyId, null)
      : null;
    if (response) {
      if (response.success) {
        setSelectedCustomer(null);
        onCustomerSelect?.(null);
      } else {
        showToast(
          "Customer unassignment failed. Please try again.",
          Intent.DANGER
        );
      }
    }
    setLoading(false);
  };

  const handleViewCustomer = async () => {
    if (selectedCustomer) {
      window.open(`/customers/${selectedCustomer.id}`, "_blank");
    }
  };

  return (
    <div>
      <ButtonGroup>
        <Select<Customer>
          items={sortedCustomers}
          itemPredicate={filterCustomer}
          itemRenderer={renderCustomer}
          noResults={
            <MenuItem
              disabled={true}
              text="No results"
              roleStructure="listoption"
              style={{ paddingLeft: 30, fontFamily: "Barlow" }}
            />
          }
          activeItem={selectedCustomer}
          onItemSelect={handleCustomerAssign}
          popoverProps={{ matchTargetWidth: true }}
          menuProps={{
            style: { padding: 0, maxHeight: 500, overflow: "auto" },
          }}
        >
          <Button
            icon="person"
            rightIcon="caret-down"
            alignText="left"
            loading={loading}
            className={classes.button}
            textClassName={
              selectedCustomer ? classes.label : classes.labelEmpty
            }
            text={customerFullName || "Assign a customer..."}
          />
        </Select>
        <Button
          icon="cross"
          className={classes.iconButton}
          disabled={!selectedCustomer || loading}
          onClick={handleClearSelection}
        />
        <Button
          icon="eye-open"
          className={classes.iconButton}
          disabled={!selectedCustomer || loading}
          onClick={handleViewCustomer}
        />
      </ButtonGroup>
    </div>
  );
}
