import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "normalize.css";
import { createUseStyles } from "react-jss";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ReactFlowProvider } from "reactflow";
import { CoreDataProvider } from "src/core/CoreDataContext";
import "../../ui/globals/highstockGlobals";
import PlatformVerticalNavigationBar from "../../ui/navigation/PlatformVerticalNavigationBar.react";
import PlatformDigitalTwinPage from "../digitalTwin/PlatformDigitalTwinPage.react";
import PlatformHomePage from "./PlatformHomePage.react";
import { PlatformHorizontalNavigationBar } from "./PlatformHorizontalNavigationBar.react";
import PlatformLoginPage from "./PlatformLoginPage.react";
import PlatformSiteBackground from "./PlatformSiteBackground.react";
import PlatformAIAssistant from "./assistant/PlatformAssistant.react";
import PlatformBuilder from "./builder/PlatformBuilder.react";
import PlatformCampaigns from "./campaigns/PlatformCampaigns.react";
import PlatformCanvas from "./canvas/PlatformCanvas.react";
import { useAuthentication } from "./common/authentication/authentication";
import PlatformDocumentComposer from "./composer/PlatformDocumentComposer.react";
import PlatformSiteContractDetailViewPage from "./contracts/PlatformSiteContractDetailViewPage.react";
import PlatformSiteCustomerDetailViewPage from "./customers/PlatformSiteCustomerDetailViewPage.react";
import PlatformSiteCustomerListViewPage from "./customers/PlatformSiteCustomerListViewPage.react";
import PlatformDatalayerGridPage from "./datalayer/PlatformDatalayerGridPage.react";
import PlatformDeviceListViewPage from "./devices/PlatformDeviceListViewPage.react";
import PlatformSiteDeviceDetailViewPage from "./devices/PlatformSiteDeviceDetailViewPage.react";
import PlatformSiteDeviceListViewPage from "./devices/PlatformSiteDeviceListViewPage.react";
import PlatformTariffDetailView from "./devices/PlatformTariffDetailView.react";
import PlatformTariffListView from "./devices/PlatformTariffListView.react";
import PlatformVendorAccountDeviceEnrolmentPage from "./devices/PlatformVendorAccountDeviceEnrolmentPage.react";
import { useEnterpriseLabels } from "./enterpriseLabels/useEnterpriseLabels";
import PlatformFilesystem from "./filesystem/PlatformFilesystem.react";
import PlatformSiteInvoiceDetailViewPage from "./invoices/PlatformSiteInvoiceDetailViewPage.react";
import PlatformSitePropertyDetailViewPage from "./properties/PlatformSitePropertyDetailViewPage.react";
import PlatformSitePropertyListViewPage from "./properties/PlatformSitePropertyListViewPage.react";
import CoreEntitiesProvider from "./providers/CoreEntitiesProvider.react";
import PlatformSiteSettingsPage from "./settings/PlatformSiteSettingsPage";
import PlatformDataStudio from "./studio/PlatformDataStudio.react";
import PlatformSiteVendorAccountDetailViewPage from "./vendorAccounts/PlatformSiteVendorAccountsDetailViewPage.react";
import PlatformSiteVendorAccountListViewPage from "./vendorAccounts/PlatformSiteVendorAccountsListViewPage.react";

const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "row",
  },
  content: {
    overflowX: "hidden",
    flex: 1,
  },
});

export default function PlatformSiteApp() {
  const { isLoggedIn } = useAuthentication();
  const { selectedLabel } = useEnterpriseLabels();
  const classes = useStyles();

  return (
    <Router key={selectedLabel}>
      {!isLoggedIn ? (
        <PlatformLoginPage />
      ) : (
        <>
          <CoreDataProvider>
            <CoreEntitiesProvider>
              <ReactFlowProvider>
                <PlatformSiteBackground />
                <div className={`bp5-dark ${classes.container}`}>
                  <PlatformVerticalNavigationBar />
                  <PlatformHorizontalNavigationBar />
                  <div className={classes.content}>
                    <Routes>
                      <Route path="/" element={<PlatformHomePage />} />
                      <Route
                        path="/customers"
                        element={
                          <PlatformSiteCustomerListViewPage pageType="customers" />
                        }
                      />
                      <Route
                        path="/customers/:customerId"
                        element={<PlatformSiteCustomerDetailViewPage />}
                      />
                      <Route
                        path="/contracts"
                        element={
                          <PlatformSiteCustomerListViewPage pageType="contracts" />
                        }
                      />
                      <Route
                        path="/datalayer"
                        element={<PlatformDatalayerGridPage />}
                      />
                      <Route
                        path="/contracts/:contractId"
                        element={<PlatformSiteContractDetailViewPage />}
                      />
                      <Route
                        path="/invoices"
                        element={
                          <PlatformSiteCustomerListViewPage pageType="invoices" />
                        }
                      />
                      <Route
                        path="/tariffs"
                        element={<PlatformTariffListView />}
                      />
                      <Route
                        path="/tariffs/:tariffId"
                        element={<PlatformTariffDetailView />}
                      />
                      <Route
                        path="/invoices/:invoiceId"
                        element={<PlatformSiteInvoiceDetailViewPage />}
                      />
                      <Route path="/studio" element={<PlatformDataStudio />} />
                      {/*<Route
                        path="/devices"
                        element={<PlatformSiteDeviceListViewPage />}
                      />*/}
                      <Route
                        path="/devices"
                        element={<PlatformDeviceListViewPage />}
                      />
                      <Route
                        path="/devices/grid"
                        element={
                          <PlatformSiteDeviceListViewPage pageType={"Grid"} />
                        }
                      />
                      <Route
                        path="/devices/map"
                        element={
                          <PlatformSiteDeviceListViewPage pageType={"Map"} />
                        }
                      />
                      <Route
                        path="/devices/:deviceId"
                        element={<PlatformSiteDeviceDetailViewPage />}
                      />
                      <Route path="/studio" element={<PlatformDataStudio />} />
                      <Route
                        path="/assistant"
                        element={<PlatformAIAssistant />}
                      />
                      <Route
                        path="/composer/:documentID?"
                        element={<PlatformDocumentComposer />}
                      />
                      <Route path="/builder/" element={<PlatformBuilder />} />
                      <Route
                        path="/lab/"
                        element={<PlatformDigitalTwinPage />}
                      />
                      <Route
                        path="/campaigns/:campaignID?"
                        element={<PlatformCampaigns />}
                      />
                      <Route
                        path="/canvas/:canvasID?"
                        element={<PlatformCanvas />}
                      />
                      <Route
                        path="/assistant/:threadID?"
                        element={<PlatformAIAssistant />}
                      />
                      <Route path="/drive/" element={<PlatformFilesystem />} />
                      <Route
                        path="/properties"
                        element={<PlatformSitePropertyListViewPage />}
                      />
                      <Route
                        path="/properties/:propertyId"
                        element={<PlatformSitePropertyDetailViewPage />}
                      />
                      <Route
                        path="/vendor/account"
                        element={<PlatformSiteVendorAccountListViewPage />}
                      />
                      <Route
                        path="/vendor/account/:accountId"
                        element={<PlatformSiteVendorAccountDetailViewPage />}
                      />
                      <Route
                        path="/vendor/account/:accountId/asset-enrollment/:deviceEnrollmentSessionId?"
                        element={<PlatformVendorAccountDeviceEnrolmentPage />}
                      />
                      <Route
                        path="/settings"
                        element={<PlatformSiteSettingsPage />}
                      />
                    </Routes>
                  </div>
                </div>
              </ReactFlowProvider>
            </CoreEntitiesProvider>
          </CoreDataProvider>
        </>
      )}
    </Router>
  );
}
