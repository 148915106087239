import { Button, ButtonGroup } from "@blueprintjs/core";
import {
  GeoJSONSource,
  LngLatBounds,
  Map,
  MapboxOptions,
  Marker,
  NavigationControl,
  Projection,
} from "mapbox-gl";
import { Dispatch, SetStateAction } from "react";
import { createRoot, Root } from "react-dom/client";
import { createUseStyles } from "react-jss";
import Supercluster from "supercluster";
import { Battery } from "../../__generated__/types/Battery";
import PlatformBatteryMarker from "../../sites/platform/devices/PlatformBatteryMarker.react";

/* Constants  */
export const CLUSTERER_RADIUS = 40; // 0 for only same-coord points, 40 default for clustering, 20 for loose, 60 for tight

const SPIDERIFY_AFTER_ZOOM = 7;
const MAX_LEAVES_TO_SPIDERIFY = 255;
const CIRCLE_TO_SPIRAL_SWITCHOVER = 10; // Number of leaves before switching to spiral

const CIRCLE_OPTIONS = {
  distanceBetweenPoints: 50,
};

const SPIRAL_OPTIONS = {
  rotationsModifier: 1.5, // Number of spiral rotations
  initialRadius: 35, // Starting radius in pixels
  radiusIncrement: 5, // How much the radius increases per point
};

const SPIDER_LEGS = true;

const SPIDER_LEGS_PAINT_OPTION = {
  "line-width": 1,
  "line-color": "rgba(128, 128, 128, 0.5)",
  "line-dasharray": [0, 1],
};

const DEFAULT_MAP_OPTIONS: MapOptions = {
  container: "",
  style: "mapbox://styles/mapbox/dark-v11",
  center: [0, 0.000001],
  zoom: 1,
  maxZoom: 20,
  minZoom: 1,
  projection: {
    name: "mercator",
  },
  attributionControl: false,
  doubleClickZoom: false,
};

/* Interfaces */
export interface ClusterProperties {
  cluster: boolean;
  cluster_id?: number;
  point_count?: number;
  [key: string]: any;
}

export interface SuperclusterFeature {
  geometry: GeoJSON.Geometry;
  properties: ClusterProperties & Partial<Battery>;
  type: "Feature";
}

export interface MarkerWithRoot {
  marker: Marker;
  root: Root;
}

interface MapOptions {
  container: string | HTMLElement;
  style?: string;
  center?: [number, number];
  zoom?: number;
  maxZoom?: number;
  minZoom?: number;
  projection?: Projection;
  attributionControl?: boolean;
  doubleClickZoom?: boolean;
}

/* Styles */
const useStyles = createUseStyles({
  buttonGroup: {
    extends: "bp5-dark",
    flexDirection: "column",
  },
  button: {
    textAlign: "center",
    "& span": {
      color: "white !important",
    },
    "& svg": {
      width: "15px !important",
      height: "15px !important",
    },
  },
});

/* Functions */
/**
 * Initializes a Mapbox map with default settings.
 *
 * @param options - Configuration options for the map initialization.
 * @returns The initialized Mapbox `Map` instance.
 */
export const initializeMap = (options: MapOptions): Map => {
  // Merge user-provided options with defaults
  const mapOptions: MapboxOptions = {
    container: options.container,
    style: options.style || DEFAULT_MAP_OPTIONS.style,
    center: options.center || DEFAULT_MAP_OPTIONS.center,
    zoom: options.zoom || DEFAULT_MAP_OPTIONS.zoom,
    maxZoom: options.maxZoom || DEFAULT_MAP_OPTIONS.maxZoom,
    minZoom: options.minZoom || DEFAULT_MAP_OPTIONS.minZoom,
    projection: options.projection || DEFAULT_MAP_OPTIONS.projection,
    attributionControl:
      options.attributionControl ?? DEFAULT_MAP_OPTIONS.attributionControl,
    doubleClickZoom:
      options.doubleClickZoom ?? DEFAULT_MAP_OPTIONS.doubleClickZoom,
  };

  // Initialize the map
  const map = new Map(mapOptions);

  // Add navigation controls to the map (zoom and rotation)
  map.addControl(new NavigationControl(), "bottom-left");

  // Apply custom styles to navigation controls
  const styleEl = document.createElement("style");
  styleEl.textContent = `
    .mapboxgl-ctrl-group {
      margin-left: 15px !important;
      margin-top: 15px !important;
      margin-bottom: 5px !important;
      background: #3c4147 !important;
    }
    .mapboxgl-ctrl-group button {
      background-color: #3c4147 !important;
      border-color: #7c8289 !important;
      border-radius: 0 !important;
    }
    .mapboxgl-ctrl-group button.bp5-active {
      background-color: #545c66 !important;
    }
    .mapboxgl-ctrl-group button:hover {
      background-color: #545c66 !important;
    }
    .mapboxgl-ctrl-group button[disabled]:hover {
      background-color: unset !important;
    }          
    .mapboxgl-ctrl-group button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon,
    .mapboxgl-ctrl-group button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon,
    .mapboxgl-ctrl-group button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon {
      background-color: transparent;
    }
    .mapboxgl-ctrl-group button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23ffffff'%3E%3Cpath d='M14.5 8.5c-.75 0-1.5.75-1.5 1.5v3h-3c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h3v3c0 .75.75 1.5 1.5 1.5S16 19.75 16 19v-3h3c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-3v-3c0-.75-.75-1.5-1.5-1.5z'/%3E%3C/svg%3E");
    }
    .mapboxgl-ctrl-group button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23ffffff'%3E%3Cpath d='M10 13c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h9c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-9z'/%3E%3C/svg%3E");
    }
  `;
  document.head.appendChild(styleEl);

  // Remove Mapbox logo
  const logoEl = map.getContainer().querySelector(".mapboxgl-ctrl-logo");
  if (logoEl) {
    (logoEl as HTMLElement).remove();
  }

  // Handle map resize
  map.on("resize", () => {
    map.resize();
  });

  return map;
};

// Utility function to create a DOM element from a React component
export function createMarkerElement(component: JSX.Element): {
  element: HTMLElement;
  root: Root;
} {
  const el = document.createElement("div");
  const root = createRoot(el);
  root.render(component);
  return { element: el, root };
}

// Utility function to generate equidistant points in a circle
const generateEquidistantPointsInCircle = ({
  totalPoints,
  options = CIRCLE_OPTIONS,
}: {
  totalPoints: number;
  options?: typeof CIRCLE_OPTIONS;
}): { x: number; y: number }[] => {
  const points = [];
  const angleIncrement = (2 * Math.PI) / totalPoints;
  const radius = options.distanceBetweenPoints || 50;

  for (let i = 0; i < totalPoints; i++) {
    const angle = i * angleIncrement;
    points.push({
      x: radius * Math.cos(angle),
      y: radius * Math.sin(angle),
    });
  }

  return points;
};

// Utility function to generate equidistant points in a spiral
const generateEquidistantPointsInSpiral = ({
  totalPoints,
  options = SPIRAL_OPTIONS,
}: {
  totalPoints: number;
  options?: typeof SPIRAL_OPTIONS;
}): { x: number; y: number }[] => {
  const points = [];
  const angleIncrement =
    (options.rotationsModifier * 2 * Math.PI) / totalPoints;
  const initialRadius = options.initialRadius || 50;
  const radiusIncrement = options.radiusIncrement || 10;

  for (let i = 0; i < totalPoints; i++) {
    const angle = i * angleIncrement;
    const radius = initialRadius + i * radiusIncrement;
    points.push({
      x: radius * Math.cos(angle),
      y: radius * Math.sin(angle),
    });
  }

  return points;
};

// Generate leaves coordinates based on the number of leaves
const generateLeavesCoordinates = ({ nbOfLeaves }: { nbOfLeaves: number }) => {
  let points;
  if (nbOfLeaves < CIRCLE_TO_SPIRAL_SWITCHOVER) {
    points = generateEquidistantPointsInCircle({ totalPoints: nbOfLeaves });
  } else {
    points = generateEquidistantPointsInSpiral({ totalPoints: nbOfLeaves });
  }
  return points;
};

// Check if all points have the same coordinates
const allPointsHaveSameCoordinates = (
  clusterId: number,
  supercluster: Supercluster
) => {
  if (!supercluster) {
    return false;
  }

  const leaves = supercluster.getLeaves(clusterId, Infinity);
  if (leaves.length <= 1) {
    return true;
  }

  const firstPoint = leaves[0].geometry as GeoJSON.Point;
  return leaves.every(
    (leaf) =>
      (leaf.geometry as GeoJSON.Point).coordinates[0] ===
        firstPoint.coordinates[0] &&
      (leaf.geometry as GeoJSON.Point).coordinates[1] ===
        firstPoint.coordinates[1]
  );
};

// Spiderify cluster function
export const spiderifyCluster = async (
  cluster: SuperclusterFeature,
  supercluster: Supercluster,
  mapRef: React.MutableRefObject<Map | null>,
  setOpenClusterId: React.Dispatch<React.SetStateAction<number | null>>,
  spiderifiedMarkersRef: React.MutableRefObject<
    { marker: Marker; root: Root }[]
  >,
  spiderLegsSourceId: React.MutableRefObject<string>,
  openClusterId: number | null
) => {
  if (
    !supercluster ||
    !mapRef.current ||
    typeof cluster.properties.cluster_id !== "number"
  ) {
    return;
  }

  // Collapse cluster if it's already open
  if (openClusterId === cluster.properties.cluster_id) {
    setOpenClusterId(null);
    spiderifiedMarkersRef.current.forEach(({ marker, root }) => {
      marker.remove();
      root.unmount();
    });
    spiderifiedMarkersRef.current = [];

    if (mapRef.current.getLayer(spiderLegsSourceId.current)) {
      mapRef.current.removeLayer(spiderLegsSourceId.current);
    }
    if (mapRef.current.getSource(spiderLegsSourceId.current)) {
      mapRef.current.removeSource(spiderLegsSourceId.current);
    }

    return;
  }

  if (openClusterId !== null) {
    spiderifiedMarkersRef.current.forEach(({ marker, root }) => {
      marker.remove();
      root.unmount();
    });
    spiderifiedMarkersRef.current = [];

    if (mapRef.current.getLayer(spiderLegsSourceId.current)) {
      mapRef.current.removeLayer(spiderLegsSourceId.current);
    }
    if (mapRef.current.getSource(spiderLegsSourceId.current)) {
      mapRef.current.removeSource(spiderLegsSourceId.current);
    }

    setOpenClusterId(null);
  }

  const zoomLevel = Math.floor(mapRef.current.getZoom());
  if (
    (CLUSTERER_RADIUS > 0 && zoomLevel < SPIDERIFY_AFTER_ZOOM) ||
    !allPointsHaveSameCoordinates(cluster.properties.cluster_id, supercluster)
  ) {
    const expansionZoom = Math.min(
      supercluster.getClusterExpansionZoom(cluster.properties.cluster_id),
      10
    );

    mapRef.current.easeTo({
      center:
        cluster.geometry.type === "Point"
          ? (cluster.geometry.coordinates as [number, number])
          : [0, 0],
      zoom: expansionZoom,
    });
    return;
  }

  const leaves = supercluster.getLeaves(
    cluster.properties.cluster_id,
    MAX_LEAVES_TO_SPIDERIFY,
    0
  );

  if (!leaves || leaves.length <= 1) {
    return;
  }

  const points = generateLeavesCoordinates({ nbOfLeaves: leaves.length });

  if (cluster.geometry.type !== "Point") {
    return;
  }

  const [lng, lat] = cluster.geometry.coordinates as [number, number];
  const clusterCenter = mapRef.current.project([lng, lat]);

  spiderifiedMarkersRef.current.forEach(({ marker, root }) => {
    marker.remove();
    root.unmount();
  });
  spiderifiedMarkersRef.current = [];

  if (mapRef.current.getLayer(spiderLegsSourceId.current)) {
    mapRef.current.removeLayer(spiderLegsSourceId.current);
  }
  if (mapRef.current.getSource(spiderLegsSourceId.current)) {
    mapRef.current.removeSource(spiderLegsSourceId.current);
  }

  const spiderLegs: GeoJSON.Feature<GeoJSON.LineString>[] = [];

  // Create a list of starting coordinates for animation
  const initialCoordinates: [number, number][] = [];
  const finalCoordinates: [number, number][] = [];

  leaves.forEach((leaf, index) => {
    const offset = points[index];
    const spiderPoint = mapRef.current?.unproject([
      clusterCenter.x + offset.x,
      clusterCenter.y + offset.y,
    ]);

    // Store the initial (cluster center) and final coordinates for the animation
    initialCoordinates.push([lng, lat]);

    if (spiderPoint) {
      finalCoordinates.push([spiderPoint.lng, spiderPoint.lat]);
    }

    if (SPIDER_LEGS) {
      spiderLegs.push({
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: [
            [lng, lat], // Start with the leg at the cluster center
            [lng, lat], // Initially, leg is very short
          ],
        },
      });
    }
  });

  if (SPIDER_LEGS && spiderLegs.length > 0) {
    const spiderLegsGeoJSON: GeoJSON.FeatureCollection<GeoJSON.LineString> = {
      type: "FeatureCollection",
      features: spiderLegs,
    };

    mapRef.current.addSource(spiderLegsSourceId.current, {
      type: "geojson",
      data: spiderLegsGeoJSON,
    });

    mapRef.current.addLayer({
      id: spiderLegsSourceId.current,
      type: "line",
      source: spiderLegsSourceId.current,
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: SPIDER_LEGS_PAINT_OPTION,
    });

    // Animate the legs growing out from the cluster center
    let progress = 0;
    const duration = 1000; // Set to 1 second (1000ms) for a reasonable animation speed

    const animateSpiderLegs = () => {
      progress += 16; // Assuming 60fps, increment by ~16ms per frame
      const factor = Math.min(progress / duration, 1); // Ensure progress doesn't exceed 1

      // Update each spider leg's coordinates based on the animation progress
      const updatedSpiderLegs = spiderLegs.map((leg, index) => {
        const [startLng, startLat] = initialCoordinates[index];
        const [endLng, endLat] = finalCoordinates[index];

        const intermediateLng = startLng + (endLng - startLng) * factor;
        const intermediateLat = startLat + (endLat - startLat) * factor;

        return {
          ...leg,
          geometry: {
            ...leg.geometry,
            coordinates: [
              [startLng, startLat],
              [intermediateLng, intermediateLat], // Grow the leg towards the final position
            ],
          },
        };
      });

      // Get the source and cast it as GeoJSONSource
      const source = mapRef.current?.getSource(spiderLegsSourceId.current) as
        | GeoJSONSource
        | undefined;
      if (source) {
        // Update the source with the new leg positions
        source.setData({
          type: "FeatureCollection",
          features: updatedSpiderLegs,
        });
      }

      if (factor < 1) {
        requestAnimationFrame(animateSpiderLegs); // Continue the animation
      } else {
        // After animation is done, display child markers
        leaves.forEach((leaf, index) => {
          const offset = points[index];
          const spiderPoint = mapRef.current?.unproject([
            clusterCenter.x + offset.x,
            clusterCenter.y + offset.y,
          ]);

          const { element, root } = createMarkerElement(
            <PlatformBatteryMarker batteries={[leaf.properties as Battery]} />
          );

          Object.assign(element.style, {
            transform: "scale(0)", // Start hidden
            opacity: "0",
            transition: "transform 0.3s ease-out, opacity 0.3s ease-out",
          });

          if (spiderPoint) {
            const marker = new Marker(element)
              .setLngLat([spiderPoint.lng, spiderPoint.lat])
              .addTo(mapRef.current!);

            spiderifiedMarkersRef.current.push({ marker, root });

            // Delay the appearance of the markers until the legs finish animating
            setTimeout(() => {
              element.style.transform = "scale(1)"; // Trigger animation
              element.style.opacity = "1";
            }, 0);
          }
        });
      }
    };

    requestAnimationFrame(animateSpiderLegs); // Start the animation
  }

  setOpenClusterId(cluster.properties.cluster_id);
};

// Handle cluster click function
export const handleClusterClick = (
  cluster: SuperclusterFeature,
  supercluster: Supercluster,
  mapRef: React.MutableRefObject<Map | null>,
  openClusterId: number | null,
  setOpenClusterId: React.Dispatch<React.SetStateAction<number | null>>,
  spiderifiedMarkersRef: React.MutableRefObject<
    { marker: Marker; root: Root }[]
  >,
  spiderLegsSourceId: React.MutableRefObject<string>,
  clusterToSpiderify: React.MutableRefObject<SuperclusterFeature | null>
) => {
  if (!supercluster || !mapRef.current) {
    return;
  }

  const clusterId = cluster.properties.cluster_id;
  if (typeof clusterId !== "number") {
    return;
  }

  // Check if all points in the cluster have the same coordinates
  const isSameCoordinates = allPointsHaveSameCoordinates(
    clusterId,
    supercluster
  );

  if (isSameCoordinates) {
    // If the cluster is already expanded (open), collapse it
    if (openClusterId === clusterId) {
      const currentSpiderLegsSourceId = spiderLegsSourceId.current;

      // Remove spiderified markers
      spiderifiedMarkersRef.current.forEach(({ marker, root }) => {
        marker.remove();
        root.unmount();
      });
      spiderifiedMarkersRef.current = [];

      // Remove spider legs
      if (mapRef.current.getLayer(currentSpiderLegsSourceId)) {
        mapRef.current.removeLayer(currentSpiderLegsSourceId);
      }
      if (mapRef.current.getSource(currentSpiderLegsSourceId)) {
        mapRef.current.removeSource(currentSpiderLegsSourceId);
      }

      // Reset the open cluster ID
      setOpenClusterId(null);
      return;
    }

    clusterToSpiderify.current = cluster;

    // Zoom in to expand the cluster
    mapRef.current.easeTo({
      center:
        cluster.geometry.type === "Point"
          ? (cluster.geometry.coordinates as [number, number])
          : [0, 0],
      zoom: 10,
    });
  } else {
    // If the points don't have the same coordinates, spiderify the cluster
    spiderifyCluster(
      cluster,
      supercluster,
      mapRef,
      setOpenClusterId,
      spiderifiedMarkersRef,
      spiderLegsSourceId,
      openClusterId
    );
  }
};

export const handleMoveEnd = (
  mapRef: React.MutableRefObject<Map | null>,
  setBounds: Dispatch<SetStateAction<LngLatBounds | null>>,
  setZoom: Dispatch<SetStateAction<number>>,
  previousZoomRef: React.MutableRefObject<number>
) => {
  if (!mapRef.current) {
    return;
  }

  const newBounds = mapRef.current.getBounds();
  setBounds(newBounds);

  const currentZoom = mapRef.current.getZoom();
  setZoom(currentZoom);

  // Update the previous zoom level
  previousZoomRef.current = currentZoom;
};

export const handleMapChange = (
  mapRef: React.MutableRefObject<Map | null>,
  spiderifiedMarkersRef: React.MutableRefObject<
    { marker: Marker; root: Root }[]
  >,
  spiderLegsSourceId: React.MutableRefObject<string>,
  setOpenClusterId: Dispatch<SetStateAction<number | null>>,
  previousZoomRef: React.MutableRefObject<number>
) => {
  const currentSpiderLegsSourceId = spiderLegsSourceId.current;

  const handleZoomEnd = () => {
    if (!mapRef.current) {
      return;
    }

    const currentZoom = mapRef.current.getZoom();
    const previousZoom = previousZoomRef.current;

    // Check if the user is zooming out
    if (currentZoom < previousZoom) {
      // Remove spiderified markers
      spiderifiedMarkersRef.current.forEach(({ marker, root }) => {
        marker.remove();
        root.unmount();
      });
      spiderifiedMarkersRef.current = [];

      // Remove spider legs using the captured local variable
      if (mapRef.current.getLayer(currentSpiderLegsSourceId)) {
        mapRef.current.removeLayer(currentSpiderLegsSourceId);
      }
      if (mapRef.current.getSource(currentSpiderLegsSourceId)) {
        mapRef.current.removeSource(currentSpiderLegsSourceId);
      }

      // Reset the open cluster ID
      setOpenClusterId(null);
    }

    // Update the previous zoom level
    previousZoomRef.current = currentZoom;
  };

  if (mapRef.current) {
    mapRef.current.on("zoomend", handleZoomEnd);
  }

  return () => {
    if (mapRef.current) {
      mapRef.current.off("zoomend", handleZoomEnd);
    }

    if (mapRef.current) {
      if (mapRef.current.getLayer(currentSpiderLegsSourceId)) {
        mapRef.current.removeLayer(currentSpiderLegsSourceId);
      }
      if (mapRef.current.getSource(currentSpiderLegsSourceId)) {
        mapRef.current.removeSource(currentSpiderLegsSourceId);
      }
    }
  };
};

// Custom control buttons for toggling clustering
const ClusterControls: React.FC<{
  clusterRadius: number;
  onChange: (radius: number) => void;
}> = ({ clusterRadius, onChange }) => {
  const classes = useStyles();

  return (
    <ButtonGroup className={classes.buttonGroup}>
      <Button
        icon={"layout-grid"}
        active={clusterRadius === 0}
        onClick={() => onChange(0)}
        minimal
        small
        title="Unclustered View"
        className={classes.button}
      />
      <Button
        icon={"layout-skew-grid"}
        active={clusterRadius === CLUSTERER_RADIUS}
        onClick={() => onChange(CLUSTERER_RADIUS)}
        minimal
        small
        title="Clustered View"
        className={classes.button}
      />
    </ButtonGroup>
  );
};

// Custom control class for toggling clustering
export class ClusterToggleControl {
  private container: HTMLElement;
  private clusterRadiusRef: React.MutableRefObject<number>;
  private setClusterRadius: React.Dispatch<React.SetStateAction<number>>;
  private root: Root | null = null;

  constructor(
    clusterRadiusRef: React.MutableRefObject<number>,
    setClusterRadius: React.Dispatch<React.SetStateAction<number>>
  ) {
    this.clusterRadiusRef = clusterRadiusRef;
    this.setClusterRadius = setClusterRadius;

    this.container = document.createElement("div");
    this.container.className = "mapboxgl-ctrl mapboxgl-ctrl-group bp5-dark";
    this.container.style.backgroundColor = "transparent";
    this.container.style.border = "none";

    this.root = createRoot(this.container);
    this.root.render(
      <ClusterControls
        clusterRadius={this.clusterRadiusRef.current}
        onChange={(radius) => {
          this.setClusterRadius(radius);
        }}
      />
    );
  }

  onAdd(): HTMLElement {
    return this.container;
  }

  onRemove(): void {
    if (this.root) {
      this.root.unmount();
      this.root = null;
    }
    if (this.container.parentNode) {
      this.container.parentNode.removeChild(this.container);
    }
  }

  update() {
    if (this.root) {
      this.root.render(
        <ClusterControls
          clusterRadius={this.clusterRadiusRef.current}
          onChange={(radius) => {
            this.setClusterRadius(radius);
          }}
        />
      );
    }
  }
}
