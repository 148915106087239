import {
  IFilter,
  IFilterDefinition,
} from "src/common/table/PlatformPowerSearchBar.react";
import { TableOrdering } from "src/common/table/PlatformTable.react";
import { PAGE_SIZE_OPTIONS } from "src/common/table/PlatformTablePagination";

export function extractOrderingFromURL(
  search: string,
  fieldMap: { [key: string]: string } = {}
): TableOrdering[] {
  const params = new URLSearchParams(search);
  const orderingParam = params.get("ordering");
  if (!orderingParam) {
    return [];
  }

  return orderingParam.split(",").map((param) => {
    const isDesc = param.startsWith("-");
    const field = isDesc ? param.slice(1).toUpperCase() : param.toUpperCase();
    const order = isDesc ? ("DESC" as const) : ("ASC" as const);
    const mappedField = fieldMap[field.toLowerCase()] || field;
    return { field: mappedField, order };
  });
}

export function extractFiltersFromURL(
  search: string,
  filterDefs: IFilterDefinition[]
): IFilter[] {
  const params = new URLSearchParams(search);
  const filters: IFilter[] = [];

  params.forEach((value, key) => {
    const [fieldName, operator] = key.split("__");
    const filterDef = filterDefs.find((def) => def.id === fieldName);

    if (filterDef) {
      let filterOperator: string;
      switch (operator) {
        case "istartswith":
          filterOperator = "starts with";
          break;
        case "iendswith":
          filterOperator = "ends with";
          break;
        case "icontains":
          filterOperator = "contains";
          break;
        default:
          filterOperator = "equals";
      }

      filters.push({
        filterDefinitionId: fieldName,
        operator: filterOperator,
        value: value,
      });
    }
  });

  return filters;
}

export const parseURL = (
  search: string,
  isUrlUpdate: React.MutableRefObject<boolean>,
  navigate: (url: string, options: object) => void,
  filterDefs: IFilterDefinition[],
  defaultPageSize: number,
  fieldMap: { [key: string]: string } = {}
) => {
  const searchParams = new URLSearchParams(search);
  const limit = searchParams.get("limit");
  const offset = searchParams.get("offset");

  let page = 1;
  let pageSize = defaultPageSize;

  if (limit) {
    pageSize = parseInt(limit, 10);

    if (!PAGE_SIZE_OPTIONS.includes(pageSize)) {
      const params = new URLSearchParams(search);
      params.set("limit", defaultPageSize.toString());
      params.set("offset", "0"); // Reset offset to 0 when changing page size
      isUrlUpdate.current = false;
      navigate(`/tariffs/?${params.toString()}`, { replace: true });
    }
  }

  if (offset) {
    page = Math.floor(parseInt(offset, 10) / pageSize) + 1;
  }

  return {
    page,
    pageSize,
    ordering: extractOrderingFromURL(search, fieldMap),
    filters: extractFiltersFromURL(search, filterDefs),
  };
};

export function buildUrlParams(
  page: number,
  pageSize: number,
  ordering: TableOrdering[],
  filters: IFilter[],
  filterDefs: IFilterDefinition[],
  fieldMap: { [key: string]: string } = {}
): string {
  const params = new URLSearchParams();

  params.set("limit", pageSize.toString());
  params.set("offset", ((page - 1) * pageSize).toString());

  if (ordering.length > 0) {
    const orderingStr = ordering
      .map(({ field, order }) => {
        const prefix = order === "DESC" ? "-" : "";
        const apiField =
          Object.entries(fieldMap).find(([_, value]) => value === field)?.[0] ||
          field.toLowerCase();
        return `${prefix}${apiField}`;
      })
      .join(",");
    params.set("ordering", orderingStr);
  }

  if (filters.length > 0) {
    filters.forEach((f) => {
      const filterDefinition = filterDefs.find(
        (fd) => fd.id === f.filterDefinitionId
      );
      if (filterDefinition) {
        let paramKey = filterDefinition.id;
        switch (f.operator) {
          case "starts with":
            paramKey += "__istartswith";
            break;
          case "ends with":
            paramKey += "__iendswith";
            break;
          case "contains":
            paramKey += "__icontains";
            break;
          // "equals" doesn't need a suffix
        }
        params.set(paramKey, f.value ?? "");
      }
    });
  }

  return params.toString().replace(/%2C/g, ",");
}

export function updateURL(
  currentUrl: string,
  navigate: (url: string, options: object) => void,
  isUrlUpdate: React.MutableRefObject<boolean>
) {
  if (!isUrlUpdate.current) {
    navigate(currentUrl, { replace: true });
  } else {
    isUrlUpdate.current = false;
  }
}

export function createOrderingUpdater(newOrdering: TableOrdering[]) {
  return (prevOrdering: TableOrdering[]): TableOrdering[] => {
    if (newOrdering.length === 0) {
      // This case should not occur anymore, but we'll keep it for safety
      return prevOrdering;
    }

    const { field, order } = newOrdering[0];
    const existingOrderIndex = prevOrdering.findIndex(
      (item) => item.field === field
    );

    if (order === null) {
      // We're clearing the ordering for this column
      return prevOrdering.filter((item) => item.field !== field);
    }

    if (existingOrderIndex === -1) {
      // If the field doesn't exist in the current ordering, add it to the beginning
      return [newOrdering[0], ...prevOrdering];
    } else {
      // If the field exists, update its order
      const updatedOrdering = prevOrdering.filter(
        (item) => item.field !== field
      );
      return [newOrdering[0], ...updatedOrdering];
    }
  };
}

export function calculateMaxPage(totalItems: number, pageSize: number): number {
  return Math.ceil(totalItems / pageSize) || 1;
}
