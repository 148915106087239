import {
  Button,
  ButtonGroup,
  Dialog,
  DialogProps,
  FormGroup,
  H4,
  Icon,
  InputGroup,
  Intent,
  MenuItem,
  Spinner,
} from "@blueprintjs/core";
import {
  ItemPredicate,
  ItemRenderer,
  Select,
  Suggest,
} from "@blueprintjs/select";
import { PlatformCustomer } from "@ec1/types/PlatformCustomer";
import { PlatformProperty } from "@ec1/types/PlatformProperty";
import { PlatformPropertyAddress } from "@ec1/types/PlatformPropertyAddress";
import throttle from "lodash/throttle";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { useFetch } from "src/common/fetcher/effects";
import { Ec1Toaster } from "../../../ui/components/Ec1Toaster.react";
import countries from "../../../ui/geoData/countries.json";
import timezones from "../../../ui/geoData/timezones.json";
import capitalizeName from "../customers/capitalizeName";
import { useCustomers } from "../customers/customers";
import PlatformSitePropertyMap from "./PlatformSitePropertyMap.react";
import { PropertyData, useProperties } from "./properties";

const MIN_CHARS_TO_SEARCH = 3;
const THROTTLE_DELAY = 300; // milliseconds

interface PlatformPropertyDialogProps extends DialogProps {
  isEdit: boolean;
  propertyData?: PlatformProperty | null;
  address?: PlatformPropertyAddress | null;
  onEnrollmentSuccess?: (propertyId: number) => void;
  onEditSuccess?: () => void;
  setIsLoadingParent: (loading: boolean) => void;
  customerId?: number | null;
  onCustomerSelect?: (customerId: number | null) => void;
}

type Country = {
  id: number;
  name: string;
  alpha2: string;
  alpha3: string;
};

type Timezone = {
  key: string;
  value: string;
  abbreviation: string[];
  utc_time: string;
};

const useStyles = createUseStyles({
  dialog: {
    fontFamily: "Barlow",
    padding: 24,
    width: "400px",
    boxShadow: "inset 0 0 0 1px rgba(255, 255, 255, 0.2) !important",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "24px",
  },
  validationError: {
    color: "#f17575",
    height: 10,
  },
  selectError: {
    border: "1px solid #f17575",
    borderRadius: 3,
    width: 240,
    outline: "none",
    height: 10,
  },
  button: {
    width: 240,
    outline: "none",
  },
  label: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontFamily: "Barlow",
    paddingLeft: 2,
  },
  labelEmpty: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontFamily: "Barlow",
    opacity: 0.3,
  },
  iconButton: {
    outline: "none",
  },
  disabledIconButton: {
    outline: "none",
    opacity: 0.5,
  },
  addressInput: {
    "& .bp5-input": {
      color: "white !important",
      backgroundColor: "#383e47 !important",
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
      paddingLeft: "35px !important",
      boxShadow:
        "inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 2px rgba(17, 20, 24, 0.4) !important",
      "&::placeholder": {
        color: "rgb(246, 247, 249) !important",
        opacity: 0.3,
      },
      "&:hover": {
        backgroundColor: "#2f343c !important",
      },
    },
  },
  addressInputError: {
    "& .bp5-input": {
      color: "white !important",
      backgroundColor: "#383e47 !important",
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
      paddingLeft: "35px !important",
      boxShadow:
        "inset 0 0 0 1px #f17575, 0 1px 2px rgba(17, 20, 24, 0.4) !important",
      "&::placeholder": {
        color: "rgb(246, 247, 249) !important",
        opacity: 0.3,
      },
      "&:hover": {
        backgroundColor: "#2f343c !important",
      },
    },
  },
  addressInputDisabled: {
    opacity: 0.5,
    "& .bp5-input": {
      color: "white !important",
      backgroundColor: "#383e47 !important",
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
      paddingLeft: "35px !important",
      "&::placeholder": {
        color: "rgb(246, 247, 249) !important",
        opacity: 0.3,
      },
    },
  },
  popover: {
    minWidth: 352,
    maxWidth: 500,
  },
});

const normalizeString = (str: string): string => {
  return str.toLowerCase().replace(/[^\w\s]/g, "");
};

const filterCustomer: ItemPredicate<PlatformCustomer> = (
  query,
  customer,
  _index,
  exactMatch
) => {
  const fullName = capitalizeName(
    `${customer.first_name} ${customer.last_name}`
  );
  const normalizedName = fullName.toLowerCase();
  const normalizedQuery = query.trim().toLowerCase();

  if (exactMatch) {
    return normalizedName === normalizedQuery;
  }
  return normalizedName.includes(normalizedQuery);
};

const renderCustomer: ItemRenderer<PlatformCustomer> = (
  customer,
  { handleClick, handleFocus, modifiers }
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  return (
    <MenuItem
      style={{ paddingLeft: 30, fontFamily: "Barlow" }}
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={customer.id}
      onClick={handleClick}
      onFocus={handleFocus}
      roleStructure="listoption"
      text={capitalizeName(`${customer.first_name} ${customer.last_name}`)}
    />
  );
};

const filterCountry: ItemPredicate<Country> = (
  query,
  country,
  _index,
  exactMatch
) => {
  const fullName = `${country.name} ${country.alpha2} ${country.alpha3}`;
  const normalizedName = normalizeString(fullName);
  const normalizedQuery = normalizeString(query.trim());

  if (exactMatch) {
    return normalizedName === normalizedQuery;
  }

  // Implement fuzzy search
  const words = normalizedQuery.split(/\s+/);
  return words.every((word) => normalizedName.includes(word));
};

const renderCountry: ItemRenderer<Country> = (
  country,
  { handleClick, handleFocus, modifiers }
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  return (
    <MenuItem
      style={{ paddingLeft: 30, fontFamily: "Barlow" }}
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={country.id}
      onClick={handleClick}
      onFocus={handleFocus}
      roleStructure="listoption"
      text={country.name}
    />
  );
};

const renderAddress: ItemRenderer<PlatformPropertyAddress> = (
  address,
  { handleClick, handleFocus, modifiers }
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  return (
    <MenuItem
      style={{ paddingLeft: 30, fontFamily: "Barlow" }}
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={address.freeform_address}
      onClick={handleClick}
      onFocus={handleFocus}
      roleStructure="listoption"
      text={address.freeform_address}
    />
  );
};

const filterTimezone: ItemPredicate<Timezone> = (
  query,
  timezone,
  _index,
  exactMatch
) => {
  const fullName = `${timezone.key} ${
    timezone.value
  } ${timezone.abbreviation?.join(" ")} ${timezone.utc_time}`;
  const normalizedName = normalizeString(fullName);
  const normalizedQuery = normalizeString(query.trim());

  if (exactMatch) {
    return normalizedName === normalizedQuery;
  }

  // Implement fuzzy search
  const words = normalizedQuery.split(/\s+/);
  return words.every((word) => normalizedName.includes(word));
};

const renderTimezone: ItemRenderer<Timezone> = (
  timezone,
  { handleClick, handleFocus, modifiers }
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  return (
    <MenuItem
      style={{ paddingLeft: 30, fontFamily: "Barlow" }}
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={timezone.value}
      onClick={handleClick}
      onFocus={handleFocus}
      roleStructure="listoption"
      text={
        <span style={{ fontFamily: "Barlow" }}>
          <span style={{ paddingRight: 5 }}>{timezone.value}</span>
          <span
            style={{
              color: "rgba(255, 255, 255, 0.5)",
              fontSize: "0.8em",
              fontFamily: "Barlow",
            }}
          >
            ({timezone.utc_time})
          </span>
        </span>
      }
    />
  );
};

export default function PlatformPropertyDialog({
  isEdit,
  propertyData,
  address,
  onEnrollmentSuccess,
  onEditSuccess,
  setIsLoadingParent,
  onClose,
  customerId,
  onCustomerSelect,
  ...dialogProps
}: PlatformPropertyDialogProps) {
  const classes = useStyles();

  const { customers } = useCustomers();
  const { addProperty, editProperty } = useProperties();

  const [uid, setUid] = useState("");
  const [uidValidationError, setUidValidationError] = useState("");
  const [uidIntent, setUidIntent] = useState<Intent>(Intent.NONE);

  const [isInitialLoad, setIsInitialLoad] = useState(false);

  const [selectedCustomer, setSelectedCustomer] =
    useState<PlatformCustomer | null>(null);
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [selectedAddress, setSelectedAddress] =
    useState<PlatformPropertyAddress | null>(null);

  const [selectedTimezone, setSelectedTimezone] = useState<Timezone | null>(
    null
  );
  const [timezoneValidationError, setTimezoneValidationError] = useState("");
  const [timezoneIntent, setTimezoneIntent] = useState<Intent>(Intent.NONE);

  const [suggestKey, setSuggestKey] = useState(0);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isAddressLoading, setIsAddressLoading] = useState(true);
  const [addressQuery, setAddressQuery] = useState("");
  const [addressResults, setAddressResults] = useState<
    PlatformPropertyAddress[]
  >([]);
  const throttledSetAddressQueryRef = useRef<ReturnType<
    typeof throttle
  > | null>(null);

  const [addressValidationError, setAddressValidationError] = useState("");
  const [addressIntent, setAddressIntent] = useState<Intent>(Intent.NONE);

  const [latitude, setLatitude] = useState<number | null>(null);
  const [longitude, setLongitude] = useState<number | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { data: addressData } = useFetch<PlatformPropertyAddress[]>(
    addressQuery.length >= MIN_CHARS_TO_SEARCH && selectedCountry
      ? `/api/address/autocomplete/?search_text=${encodeURIComponent(
          addressQuery
        )}&country_code=${selectedCountry.alpha2}`
      : null
  );

  useEffect(() => {
    if (addressData) {
      setAddressResults(addressData);
      setIsAddressLoading(false);
    }
  }, [addressData]);

  useEffect(() => {
    throttledSetAddressQueryRef.current = throttle((value: string) => {
      setAddressQuery(value);
    }, THROTTLE_DELAY);

    return () => {
      if (throttledSetAddressQueryRef.current) {
        throttledSetAddressQueryRef.current.cancel();
      }
    };
  }, []);

  useEffect(() => {
    if (!isInitialLoad) {
      setSelectedAddress(null);
      setAddressQuery("");
      setAddressResults([]);
      setSuggestKey((prevKey) => prevKey + 1);
    }
    setIsInitialLoad(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, isEdit, selectedCountry]); // Do not add isInitialLoad

  const uniqueTimezones = useMemo(() => {
    const result = new Map<string, Timezone>();
    timezones.forEach((timezone) => {
      if (!result.has(timezone.value)) {
        result.set(timezone.value, timezone as Timezone);
      }
    });
    return Array.from(result.values()).sort((a, b) =>
      a.value.localeCompare(b.value)
    );
  }, []);

  const setLoadingState = useCallback(
    (loading: boolean) => {
      setIsLoading(loading);
      setIsLoadingParent(loading);
    },
    [setIsLoadingParent]
  );

  const showToast = useCallback(
    (
      message: string,
      intent: Intent,
      buttonText?: string,
      buttonOnClick?: () => void
    ) => {
      Ec1Toaster.show({
        timeout: 5000,
        message: (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: 18,
                fontFamily: "Barlow",
              }}
            >
              <div style={{ marginRight: 15, height: 20 }}>{message}</div>
              {buttonText && (
                <Button
                  intent="primary"
                  text={buttonText}
                  onClick={buttonOnClick}
                  style={{
                    height: 20,
                    padding: "0 5px",
                    minHeight: "unset",
                    minWidth: "unset",
                    fontSize: 12,
                  }}
                />
              )}
            </div>
          </>
        ),
        intent: intent,
      });
    },
    []
  );

  const customerFullName = useMemo(() => {
    if (
      selectedCustomer &&
      selectedCustomer?.first_name &&
      selectedCustomer?.last_name
    ) {
      return capitalizeName(
        `${selectedCustomer.first_name} ${selectedCustomer.last_name}`
      );
    }
    return null;
  }, [selectedCustomer]);

  const resetForm = useCallback(() => {
    setUid("");
    setSelectedCustomer(null);
    setSelectedCountry(null);
    setSelectedAddress(null);
    setSelectedTimezone(null);
    setAddressQuery("");
    setUidValidationError("");
    setUidIntent(Intent.NONE);
    setAddressValidationError("");
    setAddressIntent(Intent.NONE);
    setTimezoneValidationError("");
    setTimezoneIntent(Intent.NONE);
    setLoadingState(false);
  }, [setLoadingState]);

  const handleEnrollClick = useCallback(
    async (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();

      let isValid = true;
      if (!uid.trim()) {
        setUidIntent(Intent.DANGER);
        setUidValidationError("UID cannot be blank.");
        isValid = false;
      } else {
        setUidValidationError("");
      }

      if (
        (!selectedAddress && addressQuery !== "") ||
        (selectedCountry && !selectedAddress)
      ) {
        setAddressIntent(Intent.DANGER);
        setAddressValidationError(
          "Invalid address. Please select an address from the list."
        );
        isValid = false;
      } else {
        setAddressValidationError("");
      }

      if (!selectedTimezone) {
        setTimezoneIntent(Intent.DANGER);
        setTimezoneValidationError("Timezone cannot be blank.");
        isValid = false;
      } else {
        setTimezoneValidationError("");
      }

      if (!isValid) {
        return;
      }

      setLoadingState(true);

      try {
        const response = isEdit
          ? await editProperty({
              id: propertyData?.id,
              property_uid: uid,
              customer: selectedCustomer?.id ?? null,
              local_timezone: selectedTimezone?.value ?? null,
              address: selectedAddress ?? null,
            } as PropertyData)
          : await addProperty({
              property_uid: uid,
              customer: selectedCustomer?.id,
              local_timezone: selectedTimezone?.value,
              address: selectedAddress,
            } as PropertyData);

        if (!response) {
          setLoadingState(false);
          showToast(
            `${
              isEdit ? "Editing" : "Adding"
            } property failed. Please try again.`,
            Intent.DANGER
          );
          return;
        }

        setLoadingState(false);

        if (isEdit) {
          onEditSuccess?.();
          onCustomerSelect?.(selectedCustomer?.id ?? null);
        } else {
          onEnrollmentSuccess?.(response);
        }

        showToast(
          `Property ${isEdit ? "edited" : "added"} successfully!`,
          Intent.SUCCESS,
          isEdit ? undefined : "View Property",
          isEdit
            ? undefined
            : () => {
                window.open(`/properties/${response}`, "_blank");
              }
        );

        resetForm();
      } catch (error) {
        setLoadingState(false);
        showToast("Adding property failed. Please try again.", Intent.DANGER);
      }
    },
    [
      uid,
      selectedCountry,
      selectedAddress,
      addressQuery,
      selectedTimezone,
      setLoadingState,
      isEdit,
      editProperty,
      propertyData?.id,
      selectedCustomer?.id,
      addProperty,
      showToast,
      resetForm,
      onEditSuccess,
      onCustomerSelect,
      onEnrollmentSuccess,
    ]
  );

  const handleClose = useCallback(
    (event?: React.SyntheticEvent<HTMLElement, Event>) => {
      if (!isLoading) {
        resetForm();
      }
      if (event && onClose) {
        onClose(event);
      }
    },
    [isLoading, onClose, resetForm]
  );

  useEffect(() => {
    if (isEdit && propertyData) {
      setUid(propertyData.property_uid);
      setSelectedCustomer(customers.find((c) => c.id === customerId) || null);
      setIsInitialLoad(true);
      if (address) {
        setSelectedCountry(
          countries.find(
            (c) =>
              c.alpha2.toLowerCase() === address.country_code?.toLowerCase()
          ) || null
        );
        setAddressQuery(address.freeform_address);
        setSelectedAddress(address);
      }
      setSelectedTimezone(
        uniqueTimezones.find((t) => t.value === propertyData.local_timezone) ||
          null
      );
    } else {
      resetForm();
    }
  }, [
    isEdit,
    propertyData,
    customerId,
    address,
    customers,
    uniqueTimezones,
    resetForm,
    setSelectedCustomer,
  ]);

  useEffect(() => {
    if (selectedAddress) {
      setLatitude(selectedAddress.latitude ?? null);
      setLongitude(selectedAddress.longitude ?? null);
    } else {
      setLatitude(null);
      setLongitude(null);
    }
  }, [selectedAddress]);

  return (
    <>
      <Dialog
        className={`bp5-dark ${classes.dialog}`}
        {...dialogProps}
        onClose={handleClose}
      >
        {isLoading && (
          <div
            style={{
              background: "rgba(0,0,0,.5",
              width: "400px",
              height:
                selectedAddress?.longitude && selectedAddress?.latitude
                  ? "706.5px"
                  : "500px",
              position: "absolute",
              margin: -24,
              borderRadius: 3,
              zIndex: 5,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop:
                  selectedAddress?.longitude && selectedAddress?.latitude
                    ? 658.5
                    : 452,
                marginLeft: 24,
                width: 100,
              }}
            >
              <Spinner size={20} style={{ marginRight: 10 }} />
              <div style={{}}>Saving...</div>
            </div>
          </div>
        )}
        <H4>{isEdit ? "Edit property" : "Add new property"}</H4>
        <div style={{ marginTop: "8px" }}>
          <FormGroup
            label="UID"
            disabled={isLoading}
            style={{ marginBottom: 5 }}
          >
            <InputGroup
              value={uid}
              intent={uidIntent}
              disabled={isLoading}
              onChange={(e) => {
                const isEmpty = e.target.value === "";
                const intent = isEmpty ? Intent.DANGER : Intent.NONE;
                const error = isEmpty ? "UID cannot be blank." : "";
                setUidIntent(intent);
                setUidValidationError(error);
                setUid(e.target.value);
              }}
            />
            <div className={classes.validationError}>{uidValidationError}</div>
          </FormGroup>
          <FormGroup
            label="Customer"
            disabled={isLoading}
            style={{ marginBottom: 18 }}
          >
            <ButtonGroup fill>
              <Select<PlatformCustomer>
                fill
                items={customers}
                itemPredicate={filterCustomer}
                itemRenderer={renderCustomer}
                noResults={
                  <MenuItem
                    disabled={true}
                    text="No results"
                    roleStructure="listoption"
                    style={{ paddingLeft: 30, fontFamily: "Barlow" }}
                  />
                }
                onItemSelect={setSelectedCustomer}
                popoverProps={{ matchTargetWidth: true }}
                menuProps={{
                  style: { padding: 0, maxHeight: 160, overflow: "auto" },
                }}
              >
                <Button
                  icon="person"
                  rightIcon="caret-down"
                  alignText="left"
                  disabled={isLoading}
                  className={classes.button}
                  textClassName={
                    selectedCustomer ? classes.label : classes.labelEmpty
                  }
                  text={customerFullName || "Select a customer..."}
                />
              </Select>
              <Button
                icon="cross"
                className={classes.iconButton}
                disabled={!selectedCustomer}
                onClick={() => {
                  setSelectedCustomer(null);
                }}
              />
            </ButtonGroup>
          </FormGroup>
          <FormGroup
            label="Country"
            disabled={isLoading}
            style={{ marginBottom: 18 }}
          >
            <ButtonGroup fill>
              <Select<Country>
                fill
                items={countries}
                itemPredicate={filterCountry}
                itemRenderer={renderCountry}
                noResults={
                  <MenuItem
                    disabled={true}
                    text="No results"
                    roleStructure="listoption"
                    style={{ paddingLeft: 30, fontFamily: "Barlow" }}
                  />
                }
                onItemSelect={(item) => {
                  setAddressIntent(Intent.NONE);
                  setAddressValidationError("");
                  setSelectedCountry(item);
                  setAddressQuery("");
                }}
                popoverProps={{ matchTargetWidth: true }}
                menuProps={{
                  style: { padding: 0, maxHeight: 160, overflow: "auto" },
                }}
              >
                <Button
                  icon="globe"
                  rightIcon="caret-down"
                  alignText="left"
                  disabled={isLoading}
                  className={classes.button}
                  textClassName={
                    selectedCountry ? classes.label : classes.labelEmpty
                  }
                  text={selectedCountry?.name || "Select a country..."}
                />
              </Select>
              <Button
                icon="cross"
                className={classes.iconButton}
                disabled={!selectedCountry}
                onClick={() => {
                  setAddressIntent(Intent.NONE);
                  setAddressValidationError("");
                  setSelectedCountry(null);
                  setSelectedAddress(null);
                  setAddressQuery("");
                }}
              />
            </ButtonGroup>
          </FormGroup>
          <FormGroup
            label="Address"
            disabled={!selectedCountry || isLoading}
            style={{ marginBottom: 8 }}
          >
            <ButtonGroup fill>
              <Suggest<PlatformPropertyAddress>
                fill
                key={suggestKey}
                className={
                  !selectedCountry || isLoading
                    ? classes.addressInputDisabled
                    : addressIntent === Intent.DANGER
                    ? classes.addressInputError
                    : classes.addressInput
                }
                disabled={!selectedCountry || isLoading}
                items={addressQuery ? addressResults || [] : []}
                itemRenderer={renderAddress}
                inputValueRenderer={(item: PlatformPropertyAddress) =>
                  item.freeform_address
                }
                noResults={
                  <MenuItem
                    disabled={true}
                    text={isAddressLoading ? "Loading..." : "No results"}
                    roleStructure="listoption"
                    style={{ paddingLeft: 30, fontFamily: "Barlow" }}
                  />
                }
                onItemSelect={(item) => {
                  setSelectedAddress(item);
                  setAddressQuery(item.freeform_address);
                  setIsPopoverOpen(false);
                  setAddressResults([]);
                }}
                query={addressQuery}
                onQueryChange={(query) => {
                  setIsAddressLoading(true);
                  setAddressQuery(query);
                  setAddressResults([]);
                  setIsPopoverOpen(query.length >= MIN_CHARS_TO_SEARCH);
                  if (
                    selectedCountry &&
                    !isLoading &&
                    throttledSetAddressQueryRef.current
                  ) {
                    throttledSetAddressQueryRef.current(query);
                  }
                }}
                popoverProps={{
                  popoverClassName: classes.popover,
                  isOpen: isPopoverOpen,
                  onInteraction: (nextOpenState) => {
                    if (!nextOpenState) {
                      setIsPopoverOpen(false);
                    }
                  },
                }}
                menuProps={{
                  style: { padding: 0, maxHeight: 160, overflow: "auto" },
                }}
                inputProps={{
                  leftIcon: (
                    <Icon
                      icon="map"
                      size={16}
                      style={{ color: "#abb3bf", marginTop: 7, paddingLeft: 3 }}
                    />
                  ),
                  placeholder:
                    !selectedCountry || isLoading
                      ? "Please select a country first..."
                      : "Type an address...",
                  style: {
                    opacity: !selectedCountry || isLoading ? 0.5 : 1,
                    cursor:
                      !selectedCountry || isLoading ? "not-allowed" : "text",
                  },
                }}
              />
              <Button
                icon="cross"
                className={
                  selectedCountry
                    ? classes.iconButton
                    : classes.disabledIconButton
                }
                disabled={!selectedAddress && !addressQuery}
                onClick={() => {
                  setSelectedAddress(null);
                  setAddressQuery("");
                  setAddressResults([]);
                  setSuggestKey((prevKey) => prevKey + 1);
                }}
              />
            </ButtonGroup>
            <div className={classes.validationError}>
              {addressValidationError}
            </div>
          </FormGroup>
          {selectedAddress?.longitude && selectedAddress?.latitude && (
            <FormGroup
              style={{
                marginTop: "-10px",
                borderRadius: "3px",
                border: "solid 1px rgba(255, 255, 255, 0.1)",
              }}
            >
              <PlatformSitePropertyMap
                longitude={longitude}
                latitude={latitude}
                disabled={
                  isLoading || !selectedAddress || !longitude || !latitude
                }
              />
            </FormGroup>
          )}
          <FormGroup label="Timezone" disabled={isLoading}>
            <ButtonGroup fill>
              <Select<Timezone>
                fill
                items={uniqueTimezones}
                itemPredicate={filterTimezone}
                itemRenderer={renderTimezone}
                noResults={
                  <MenuItem
                    disabled={true}
                    text="No results"
                    roleStructure="listoption"
                    style={{ paddingLeft: 30, fontFamily: "Barlow" }}
                  />
                }
                popoverProps={{ matchTargetWidth: true }}
                menuProps={{
                  style: { padding: 0, maxHeight: 160, overflow: "auto" },
                }}
                onItemSelect={(item) => {
                  const intent = !item ? Intent.DANGER : Intent.NONE;
                  const error = !item ? "Timezone cannot be blank." : "";
                  setTimezoneIntent(intent);
                  setTimezoneValidationError(error);
                  setSelectedTimezone(item);
                }}
              >
                <Button
                  icon="geotime"
                  rightIcon="caret-down"
                  alignText="left"
                  disabled={isLoading}
                  textClassName={
                    selectedTimezone ? classes.label : classes.labelEmpty
                  }
                  text={selectedTimezone?.value || "Select a timezone..."}
                  className={
                    timezoneIntent === Intent.DANGER
                      ? classes.selectError
                      : classes.button
                  }
                />
              </Select>
              <Button
                icon="cross"
                className={classes.iconButton}
                disabled={!selectedTimezone}
                onClick={() => {
                  setTimezoneIntent(Intent.DANGER);
                  setTimezoneValidationError("Timezone cannot be blank.");
                  setSelectedTimezone(null);
                }}
              />
            </ButtonGroup>
            <div className={classes.validationError}>
              {timezoneValidationError}
            </div>
          </FormGroup>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            outlined
            intent="danger"
            onClick={handleClose}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <div style={{ width: "8px" }} />
          <Button
            outlined
            intent="primary"
            onClick={handleEnrollClick}
            disabled={isLoading}
          >
            {isEdit ? "Save" : "Add"}
          </Button>
        </div>
      </Dialog>
    </>
  );
}
