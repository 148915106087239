import { Button } from "@blueprintjs/core";
import { Battery } from "@ec1/types/Battery";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "src/common/fetcher/effects";
import PlatformTable, {
  TableOrdering,
} from "src/common/table/PlatformTable.react";
import { EnergyUtils } from "src/ui/utils/chartUtils";
import {
  calculateMaxPage,
  createOrderingUpdater,
} from "src/ui/utils/gridStateUtils";
import PlatformListView from "../../../common/layout/PlatformListView.react";
import PlatformSiteCustomerClickableLabel from "../customers/PlatformSiteCustomerClickableLabel.react";
import BatteryBrandNameTag from "../devices/BatteryBrandNameTag.react";
import BatteryChargingModeTag from "../devices/BatteryChargingModeTag.react";
import BatteryGridStatusTag from "../devices/BatteryGridStatusTag.react";
import BatteryStationStateTag from "../devices/BatteryStationStateTag.react";
import BatteryStatusTag from "../devices/BatteryStatusTag.react";
import EllipsisNowrapTextDiv from "../devices/EllipsisNowrapTextdivProps.react";
import { useProperties } from "../properties/properties";
import { useVendorAccount } from "./vendorAccounts";

const DEFAULT_PAGE_SIZE = 15;

export default function PlatformSiteVendorAccountDetailViewPage() {
  const { accountId } = useParams<{ accountId: string }>();
  const parsedAccountId = parseInt(accountId ?? "");
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [ordering, setOrdering] = useState<TableOrdering[]>([]);
  // const [filters, setFilters] = useState<IFilter[]>([]);

  const { properties } = useProperties();

  const { vendorAccount, fetchVendorAccount } =
    useVendorAccount(parsedAccountId);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchVendorAccount(parsedAccountId);
    }, 60 * 1000);

    return () => clearInterval(interval);
  }, [parsedAccountId, fetchVendorAccount]);

  const columns = useMemo(
    () =>
      [
        {
          id: "BRAND",
          header: "Brand",
          sortable: true,
          render: (item: Battery) => <BatteryBrandNameTag battery={item} />,
        },
        {
          id: "STATION_NAME",
          header: "Station name",
          sortable: true,
          render: (item: Battery) => (
            <EllipsisNowrapTextDiv>
              {String(item.station_name ?? "-")}
            </EllipsisNowrapTextDiv>
          ),
        },
        {
          id: "STATION_STATE",
          header: "Status",
          sortable: true,
          render: (item: Battery) => <BatteryStationStateTag battery={item} />,
        },
        {
          id: "BATTERY_STATE",
          header: "Battery State",
          sortable: true,
          render: (item: Battery) => <BatteryStatusTag battery={item} />,
        },
        {
          id: "CHARGING_MODE",
          header: "Charging Mode",
          sortable: true,
          render: (item: Battery) => <BatteryChargingModeTag battery={item} />,
        },
        {
          id: "BATTERY_LEVEL",
          header: "Battery level",
          sortable: true,
          render: (item: Battery) => (
            <div>
              {item?.battery_level || item?.battery_level === 0
                ? `${item?.battery_level}%`
                : "-"}
            </div>
          ),
        },
        {
          id: "SOLAR_POWER",
          header: "Solar Power",
          sortable: true,
          render: (item: Battery) => (
            <EllipsisNowrapTextDiv>
              {String(EnergyUtils.humanize(item.solar_power ?? 0, "w"))}
            </EllipsisNowrapTextDiv>
          ),
        },
        {
          id: "GRID_STATE",
          header: "Grid Power",
          sortable: true,
          render: (item: Battery) => <BatteryGridStatusTag battery={item} />,
        },
        {
          id: "SYNCED_AT",
          header: "Last sync",
          sortable: true,
          render: (item: Battery) => (
            <EllipsisNowrapTextDiv>
              {item.synced_at
                ? String(DateTime.fromISO(item.synced_at).toRelative())
                : "-"}
            </EllipsisNowrapTextDiv>
          ),
        },
        {
          id: "CUSTOMER",
          header: "Customer",
          sortable: false,
          render: (item: Battery) => {
            const property = properties.find(
              (p) => p.id === item.assigned_property
            );
            return (
              <div style={{ marginTop: -5 }}>
                <PlatformSiteCustomerClickableLabel
                  customerId={property?.customer?.toString() ?? ""}
                />
              </div>
            );
          },
        },
      ].map((c) => ({ ...c, href: (item: Battery) => `/devices/${item.id}` })),
    [properties]
  );

  // Build the endpoint URL based on internal state
  const endpointUrl = useMemo((): string => {
    const params = new URLSearchParams();

    params.set("limit", pageSize.toString());
    params.set("offset", ((page - 1) * pageSize).toString());

    if (ordering.length > 0) {
      const orderingStr = ordering
        .map(({ field, order }) => {
          const prefix = order === "DESC" ? "-" : "";
          // Assuming you have a mapping from field to API field names if necessary
          return `${prefix}${field.toLowerCase()}`;
        })
        .join(",");
      params.set("ordering", orderingStr);
    }

    /* Uncomment to use filtering */
    // if (filters.length > 0) {
    //   filters.forEach((f) => {
    //     let paramKey = f.filterDefinitionId;
    //     switch (f.operator) {
    //       case "starts with":
    //         paramKey += "__istartswith";
    //         break;
    //       case "ends with":
    //         paramKey += "__iendswith";
    //         break;
    //       case "contains":
    //         paramKey += "__icontains";
    //         break;
    //       // "equals" doesn't need a suffix
    //     }
    //     params.set(paramKey, f.value ?? "");
    //   });
    // }

    return `/api/devices/batteries/?asset_enrollment_record_vendor_account=${parsedAccountId}${
      params.toString() ? "&" + params.toString() : ""
    }`;
  }, [page, pageSize, ordering /*, filters*/, parsedAccountId]);

  const { data: deviceData, isLoading: isLoadingBatteries } = useFetch<{
    results: Battery[];
    count: number;
  }>(endpointUrl);

  useEffect(() => {
    if (!isLoadingBatteries && deviceData) {
      const maxPage = calculateMaxPage(deviceData.count, pageSize);
      if (page > maxPage) {
        setPage(1);
      }
    }
  }, [isLoadingBatteries, deviceData, page, pageSize]);

  const handlePageChange = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const handlePageSizeChange = useCallback((newPageSize: number) => {
    setPageSize(newPageSize);
    setPage(1); // Reset to first page when changing page size
  }, []);

  const handleOrderingChange = useCallback((newOrdering: TableOrdering[]) => {
    setOrdering(createOrderingUpdater(newOrdering));
    setPage(1);
  }, []);

  /* Uncomment to use filtering */
  // const handleFilterChange = useCallback((newFilters: any[]) => {
  //   setFilters(newFilters);
  // }, []);

  return (
    <PlatformListView
      title={`Vendor Account: ${vendorAccount?.vendor_account_uid}`}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: 0,
          height: "100%",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            fontFamily: "Barlow",
            letterSpacing: 1.5,
            fontWeight: 700,
            fontSize: 24,
            color: "#D3D8DE",
            marginLeft: "4px",
            marginBottom: "12px",
          }}
        >
          <div style={{ marginTop: "12px" }}>
            <Button
              minimal
              style={{ marginRight: "12px" }}
              outlined
              rightIcon={"small-plus"}
              icon={"drive-time"}
              onClick={() => {
                navigate(
                  `/vendor/account/${parsedAccountId}/asset-enrollment/`
                );
              }}
            >
              Enroll new devices
            </Button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            paddingBottom: "12px",
            minHeight: 0,
          }}
        >
          <PlatformTable<Battery>
            interactive
            glimmer={isLoadingBatteries}
            data={deviceData?.results ?? []}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={handlePageSizeChange}
            numberOfAllItems={deviceData?.count ?? 5}
            handlePageChange={handlePageChange}
            currentPage={page}
            ordering={ordering}
            onOrderingChange={handleOrderingChange}
          />
        </div>
      </div>
    </PlatformListView>
  );
}
