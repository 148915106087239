import { Button, Icon, Popover } from "@blueprintjs/core";
import { Build, Power, PowerOff, WbSunny } from "@mui/icons-material";
import { createUseStyles } from "react-jss";
import { BatteryIcon } from "src/ui/utils/batteryIcons";
import { EnergyUtils } from "src/ui/utils/chartUtils";
import { Battery } from "../../../__generated__/types/Battery";
import BatteryChargingModeChip from "./BatteryChargingModeChip.react";
import BatteryStatusChip from "./BatteryStatusChip.react";

interface PlatformBatteryPopoverContentProps {
  battery: Battery;
}

const useStyles = createUseStyles({
  container: {
    fontFamily: "Barlow",
    padding: 10,
    cursor: "default",
  },
  header: {
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
  },
  batteryStatus: {
    marginLeft: 8,
  },
  viewButtonContainer: {
    marginLeft: 5,
    flex: 1,
  },
  stationStateContainer: {
    width: 50,
    height: 20,
    paddingTop: 1,
    paddingBottom: 1,
    textAlign: "right",
  },
  popoverContent: {
    fontFamily: "Barlow",
    padding: 10,
    maxWidth: 250,
    textAlign: "justify",
  },
  powerIconOffline: {
    fontSize: "18px !important",
    color: "#ec9a3c",
  },
  powerIconFault: {
    fontSize: "18px !important",
    color: "#c20a0a",
    padding: 2, // Padding because the icon is big
  },
  powerIconActive: {
    fontSize: "18px !important",
    color: "#238551",
  },
  divider: {
    width: "calc(100% - 4px)",
    height: 1,
    background: "#555",
    marginTop: 10,
    marginLeft: 2,
  },
  batteryInfoRow: {
    marginTop: 10,
    display: "flex",
    flexDirection: "row",
  },
  batteryLevelContainer: {
    display: "flex",
    flexDirection: "row",
    marginRight: 10,
  },
  batteryIcon: {
    marginLeft: 6,
    marginRight: 10,
    marginBottom: -2,
    fontSize: 16,
  },
  batteryValue: {
    marginTop: 1,
  },
  solarPowerContainer: {
    flex: 1,
    marginRight: 10,
    display: "flex",
    flexDirection: "row",
  },
  solarIcon: {
    marginTop: 2,
    marginRight: 4,
  },
  chargingModeRow: {
    marginTop: 14,
    display: "flex",
    flexDirection: "row",
  },
  chargingModeChip: {
    marginTop: -2,
  },
  stationName: {
    marginTop: 12,
    marginBottom: 6,
  },
});

export default function PlatformBatteryPopoverContent({
  battery,
}: PlatformBatteryPopoverContentProps) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div>
          {battery?.brand} {battery?.model}
        </div>
        {battery?.battery_status && battery.station_state !== "OFFLINE" && (
          <div className={classes.batteryStatus}>
            <BatteryStatusChip batteryStatus={battery.battery_status} />
          </div>
        )}
        <div className={classes.viewButtonContainer}>
          <Button
            minimal
            small
            onClick={() => {
              window.open(`/devices/${battery?.id}`, "_blank");
            }}
            title="View Battery"
          >
            <Icon icon="share" size={14} style={{ padding: 2 }} />
          </Button>
        </div>
        {battery.station_state && (
          <div className={classes.stationStateContainer}>
            <Popover
              interactionKind="hover-target"
              position="top"
              content={
                <div className={classes.popoverContent}>
                  Station state:{" "}
                  {battery.station_state.charAt(0).toUpperCase() +
                    battery.station_state.slice(1).toLowerCase()}
                </div>
              }
            >
              {battery.station_state === "OFFLINE" ? (
                <PowerOff className={classes.powerIconOffline} />
              ) : battery.station_state === "FAULT" ? (
                <Build className={classes.powerIconFault} />
              ) : (
                <Power className={classes.powerIconActive} />
              )}
            </Popover>
          </div>
        )}
      </div>

      <div className={classes.divider}></div>

      {typeof battery?.battery_level === "number" ||
      typeof battery?.solar_power === "number" ? (
        <div className={classes.batteryInfoRow}>
          {typeof battery?.battery_level === "number" ? (
            <div
              title="Battery level"
              className={classes.batteryLevelContainer}
            >
              <div className={classes.batteryIcon}>
                <BatteryIcon
                  batteryLevel={battery.battery_level}
                  stationState={battery.station_state}
                />
              </div>
              <div className={classes.batteryValue}>
                <strong>{battery.battery_level}%</strong>
              </div>
            </div>
          ) : null}
          {typeof battery?.solar_power === "number" ? (
            <div
              title="Solar generated"
              className={classes.solarPowerContainer}
            >
              <div className={classes.solarIcon}>
                <WbSunny style={{ fontSize: 16, color: "#ffeb3b" }} />
              </div>
              <div>
                <strong>
                  {EnergyUtils.humanize(battery.solar_power, "w")}
                </strong>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}

      <div className={classes.chargingModeRow}>
        <div>Charging mode:</div>
        <div className={classes.chargingModeChip}>
          <BatteryChargingModeChip
            chargingMode={battery.is_smart_charging ? "SMART" : "MANUAL"}
          />
        </div>
      </div>

      <div className={classes.stationName}>
        Station name: {battery.station_name ?? "-"}
      </div>
    </div>
  );
}
