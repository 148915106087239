import { Spinner } from "@blueprintjs/core";
import { BatteryMetrics1h } from "@ec1/types/BatteryMetrics1h";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import HighchartsReact from "highcharts-react-official";
import Highcharts, {
  TooltipFormatterContextObject,
} from "highcharts/highstock";
import { DateTime, Duration } from "luxon";
import { useEffect, useMemo, useRef, useState } from "react";
import { useFetch } from "src/common/fetcher/effects";
import { ChartStateV2, getChartGranularityV2 } from "src/ui/utils/chartUtils";

interface PlatformBatteryMetricsGridPowerChartProps {
  batteryId: number;
  height?: number;
  chartState: ChartStateV2;
  setChartState: React.Dispatch<React.SetStateAction<ChartStateV2>>;
  timezone?: string;
  timezoneLabel?: string;
  lastMetricTimestamp?: string;
}

export default function PlatformBatteryMetricsGridPowerChart({
  batteryId,
  height = 320,
  chartState,
  setChartState,
  timezone,
  timezoneLabel,
  lastMetricTimestamp,
}: PlatformBatteryMetricsGridPowerChartProps) {
  const chartRef = useRef<HighchartsReact.RefObject>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [chartType, setChartType] = useState("Energy");

  const handleChartTypeChange = (event: SelectChangeEvent) => {
    setChartType(event.target.value as string);
  };

  const fetchUrlMetrics = useMemo(() => {
    if (
      chartState.start &&
      chartState.end &&
      chartState.start <= DateTime.now().setZone(timezone || "system")
    ) {
      return `/api/devices/${
        chartState.granularity
      }/battery-metrics/?battery=${batteryId}&utc_time__gte=${chartState.start
        .toUTC()
        .toISO({ includeOffset: false })}&utc_time__lte=${chartState.end
        .toUTC()
        .toISO({ includeOffset: false })}&ordering=utc_time`;
    }
    return null;
  }, [batteryId, chartState, timezone]);

  const fetchUrlForecasts = null;

  /* Uncomment the below to enable forecasts */
  // const fetchUrlForecasts = useMemo(() => {
  //   if (
  //     chartState.start &&
  //     chartState.end &&
  //     (!lastMetricTimestamp ||
  //       (lastMetricTimestamp &&
  //         chartState.end > DateTime.fromISO(lastMetricTimestamp)))
  //   ) {
  //     return `/api/devices/${
  //       chartState.granularity
  //     }/battery-forecasts/?battery=${batteryId}&utc_time__gte=${chartState.start
  //       .toUTC()
  //       .toISO({ includeOffset: false })}&utc_time__lte=${chartState.end
  //       .toUTC()
  //       .toISO({ includeOffset: false })}&ordering=utc_time`;
  //   }
  //   return null;
  // }, [batteryId, chartState, lastMetricTimestamp]);

  const { data: metricData, isLoading: isLoadingMetrics } = useFetch<{
    results: BatteryMetrics1h[];
  }>(fetchUrlMetrics);

  const { data: forecastData, isLoading: isLoadingForecasts } = useFetch<{
    results: BatteryMetrics1h[];
  }>(fetchUrlForecasts);

  const dataLoaded = useMemo(() => {
    return (
      (!fetchUrlMetrics || (fetchUrlMetrics && metricData)) &&
      (!fetchUrlForecasts || (fetchUrlForecasts && forecastData))
    );
  }, [fetchUrlForecasts, fetchUrlMetrics, forecastData, metricData]);

  const noDataAvailable = useMemo(() => {
    return fetchUrlMetrics === null && fetchUrlForecasts === null;
  }, [fetchUrlForecasts, fetchUrlMetrics]);

  useEffect(() => {
    if (noDataAvailable) {
      setIsLoading(false);
    } else if (dataLoaded && chartRef.current?.chart) {
      const chart = chartRef.current.chart;

      const metrics = metricData
        ? metricData.results.map((item: BatteryMetrics1h) => {
            return [
              DateTime.fromISO(item.utc_time, {
                zone: "utc",
              }).toMillis(),
              chartType === "Power"
                ? item.grid_power_in_kw
                : item.imported_grid_energy_in_kwh
                ? -item.imported_grid_energy_in_kwh
                : 0,
              chartType === "Power"
                ? item.grid_power_in_kw
                : item.exported_grid_energy_in_kwh,
            ];
          })
        : [];

      const lastMetricTimestamp =
        metrics.length > 0 ? metrics[metrics.length - 1][0] ?? 0 : 0;

      const forecasts = forecastData
        ? forecastData.results
            .map((item: BatteryMetrics1h) => {
              return [
                DateTime.fromISO(item.utc_time, {
                  zone: "utc",
                }).toMillis(),
                chartType === "Power"
                  ? item.grid_power_in_kw
                  : item.imported_grid_energy_in_kwh
                  ? -item.imported_grid_energy_in_kwh
                  : 0,
                chartType === "Power"
                  ? item.grid_power_in_kw
                  : item.exported_grid_energy_in_kwh,
              ];
            })
            .filter((item) => item[0] && item[0] > lastMetricTimestamp)
        : ([] as any);

      const data = [...metrics, ...forecasts]
        .sort((a, b) => a[0] - b[0])
        .reduce((acc: [number, number, number][], curr) => {
          const existingIndex = acc.findIndex((item) => item[0] === curr[0]);
          if (existingIndex !== -1) {
            // If timestamp already exists, keep the value from metrics
            if (metrics.some((item) => item[0] === curr[0])) {
              return acc;
            }
            // Otherwise, update with the new value only if it's after the last metric
            if (curr[0] > lastMetricTimestamp) {
              acc[existingIndex] = curr;
            }
          } else {
            acc.push(curr);
          }
          return acc;
        }, []);

      // Create padded data set
      const paddedData: [number, number | null, number | null][] = [];
      if (chartState.start && chartState.end) {
        let currentTime = chartState.start;
        const endTime = chartState.end;

        // Adjust the start time based on granularity
        switch (chartState.granularity) {
          case "15m":
            currentTime = currentTime
              .startOf("hour")
              .plus({ minutes: Math.floor(currentTime.minute / 15) * 15 });
            break;
          case "30m":
            currentTime = currentTime
              .startOf("hour")
              .plus({ minutes: Math.floor(currentTime.minute / 30) * 30 });
            break;
          case "1h":
            currentTime = currentTime.startOf("hour");
            break;
          case "1d":
            currentTime = currentTime.startOf("day");
            break;
        }

        const granularityDuration = Duration.fromObject(
          chartState.granularity === "15m"
            ? { minutes: 15 }
            : chartState.granularity === "30m"
            ? { minutes: 30 }
            : chartState.granularity === "1h"
            ? { hours: 1 }
            : { days: 1 }
        );

        while (currentTime <= endTime) {
          paddedData.push([currentTime.toMillis(), null, null]);
          currentTime = currentTime.plus(granularityDuration);
        }
      }

      // Populate padded data with actual values
      data.forEach(([timestamp, value1, value2]) => {
        const index = paddedData.findIndex(([x]) => x === timestamp);
        if (index !== -1) {
          paddedData[index][1] = value1;
          paddedData[index][2] = value2;
        }
      });

      // Update the data
      if (chartType === "Power") {
        chart.series[0].setData(
          paddedData.map((item) => [item[0], item[1]]),
          false
        );
      } else {
        chart.series[1].setData(
          paddedData.map((item) => [item[0], item[1]]),
          false
        );
        chart.series[2].setData(
          paddedData.map((item) => [item[0], item[2]]),
          false
        );
      }

      // Calculate min and max timestamps
      const nowMillis = DateTime.now()
        .setZone(timezone || "system")
        .toMillis();
      const minX = data.length > 0 ? data[0][0] : undefined;
      const lastDataPointX =
        data.length > 0 ? data[data.length - 1][0] : undefined;

      // If chartState.end is in the future, keep it as the end extreme
      let maxX;
      if (chartState.end) {
        const chartEndMillis = chartState.end.toMillis();
        maxX = chartEndMillis > nowMillis ? chartEndMillis : lastDataPointX;
      } else {
        maxX = lastDataPointX;
      }

      if (minX !== undefined && maxX !== undefined) {
        chart.xAxis[0].setExtremes(minX, maxX);
      }

      // Redraw the chart
      chart.redraw();

      setIsLoading(false);
    }
  }, [
    chartState,
    chartType,
    dataLoaded,
    forecastData,
    isLoading,
    isLoadingForecasts,
    isLoadingMetrics,
    metricData,
    noDataAvailable,
    timezone,
  ]);

  const chartOptions = useMemo(() => {
    const localNow = DateTime.now().setZone(timezone || "system");
    const localDate = localNow.toMillis();

    return {
      chart: {
        height: height - 65,
        marginRight: 20,
        marginLeft: 64,
        marginTop: 32,
      },
      time: {
        timezone,
        useUTC: timezone !== undefined,
      },
      rangeSelector: {
        enabled: false,
      },
      xAxis: {
        minRange: 60 * 1000,
        crosshair: true,
        title: {
          y: 5,
          text: `Time<br><span style="font-size: 11px; color: #999;">${timezoneLabel}</span>`,
        },
        plotLines: [
          {
            color: "#544fc5",
            width: 2,
            value: localDate,
          },
        ],
        events: {
          afterSetExtremes: function (e: any) {
            const chart = e.target.chart;

            if (!chart || !e.min || !e.max || e.trigger !== "zoom") {
              return;
            }

            const minDateTime = DateTime.fromMillis(Math.round(e.min))
              .setZone(timezone)
              .startOf("minute");
            const maxDateTime = DateTime.fromMillis(Math.round(e.max))
              .setZone(timezone)
              .startOf("minute");

            const granularity = getChartGranularityV2(minDateTime, maxDateTime);

            setChartState({
              start: minDateTime,
              end: maxDateTime,
              granularity: granularity,
            });
          },
        },
      },
      tooltip: {
        shared: true,
        useHTML: true,
        borderColor: chartType === "Power" ? undefined : "#aaa",
        formatter: function (this: TooltipFormatterContextObject): string {
          const points = this.points;

          if (
            !points ||
            points.length === 0 ||
            typeof points[0].x !== "number"
          ) {
            return "";
          }

          const pointDate = DateTime.fromMillis(points[0].x).setZone(
            timezone || "system"
          );

          let format = "cccc, dd LLLL HH:mm";
          let startHour = pointDate.toFormat("HH:mm");
          let endHour = pointDate.plus({ hours: 1 }).toFormat("HH:mm");

          switch (chartState.granularity) {
            case "15m":
              endHour = pointDate.plus({ minutes: 15 }).toFormat("HH:mm");
              format = `cccc, dd LLLL ${startHour}${
                chartType === "Power" ? "" : `-${endHour}`
              }`;
              break;
            case "30m":
              endHour = pointDate.plus({ minutes: 30 }).toFormat("HH:mm");
              format = `cccc, dd LLLL ${startHour}${
                chartType === "Power" ? "" : `-${endHour}`
              }`;
              break;
            case "1h":
              endHour = pointDate.plus({ hours: 1 }).toFormat("HH:mm");
              format = `cccc, dd LLLL ${startHour}${
                chartType === "Power" ? "" : `-${endHour}`
              }`;
              break;
            case "1d":
              format = "cccc, dd LLLL";
              break;
            default:
              break;
          }

          const dateStr = pointDate.toFormat(format);

          let tooltipContent = `<div style="font-size: 12px; margin-bottom: 5px;">${dateStr}</div>`;

          if (
            chartType === "Energy" &&
            points.every((point) => point.y === 0)
          ) {
            return ""; // Return an empty string to not show the tooltip
          }

          let hasNonZeroValue = false;

          points.forEach((point) => {
            if (
              point.series.visible &&
              typeof point.y === "number" &&
              point.y !== 0
            ) {
              hasNonZeroValue = true;
              const seriesName = point.series.name;
              const valueStr = `${
                chartType === "Power"
                  ? point.y.toFixed(2)
                  : Math.abs(point.y).toFixed(2)
              } ${chartType === "Power" ? "kW" : "kWh"}`;
              const bulletPoint = `<span style="color:${point.color};">\u25CF</span>`;
              tooltipContent += `<div>${bulletPoint} ${seriesName}: <b>${valueStr}</b></div>`;
            }
          });

          // If there are no non-zero values, return an empty string to not show the tooltip
          if (!hasNonZeroValue) {
            return "";
          }

          return tooltipContent;
        },
        style: {
          fontFamily: "Barlow, sans-serif",
        },
      },
      yAxis: [
        {
          reversed: true,
          title: {
            text: chartType === "Power" ? "Power (kW)" : "Energy (kWh)",
          },
        },
      ],
      series: [
        {
          type: "areaspline",
          lineWidth: 1,
          color: "rgba(33, 150, 243, 0.75)",
          name: "Power",
          dataGrouping: {
            enabled: false,
          },
          showInLegend: false,
          visible: chartType === "Power",
          zoneAxis: "x",
          zones: [
            {
              value: localDate,
              color: "rgba(33, 150, 243, 0.75)",
            },
            {
              color: "rgb(33, 150, 243,0.5)",
              fillColor: "rgba(33, 150, 243, 0.3)",
              dashStyle: "ShortDash",
            },
          ],
        },
        {
          type: "column",
          lineWidth: 2,
          color: "rgb(201, 28, 37)",
          name: "Import",
          linkedTo: "import",
          dataGrouping: {
            enabled: false,
          },
          showInLegend: false,
          visible: chartType === "Energy",
          zoneAxis: "x",
          zones: [
            {
              value: localDate,
              color: "rgba(201, 28, 37, 0.75)",
            },
            {
              color: "rgb(201, 28, 37,0.5)",
            },
          ],
        },
        {
          type: "column",
          lineWidth: 2,
          color: "rgb(25, 189, 60)",
          name: "Export",
          linkedTo: "export",
          dataGrouping: {
            enabled: false,
          },
          showInLegend: false,
          visible: chartType === "Energy",
          zoneAxis: "x",
          zones: [
            {
              value: localDate,
              color: "rgba(25, 189, 60, 0.75)",
            },
            {
              color: "rgb(25, 189, 60,0.5)",
            },
          ],
        },
        // Fake series to add legend toggles
        {
          id: "import",
          type: "line",
          color: "rgb(201, 28, 37)",
          name: "Import",
          dataGrouping: {
            enabled: false,
          },
          showInLegend: true,
          visible: chartType === "Energy",
        },
        {
          id: "export",
          type: "line",
          color: "rgb(25, 189, 60)",
          name: "Export",
          showInLegend: true,
          visible: chartType === "Energy",
        },
      ],
      legend: {
        enabled: chartType === "Energy",
        align: "right",
        verticalAlign: "top",
        x: -10,
        y: -10,
        padding: 0,
        symbolWidth: 14,
        itemStyle: {
          color: "white",
          fontFamily: "Barlow",
        },
        itemHoverStyle: {
          color: "#ccc",
          fontFamily: "Barlow",
        },
      },
      loading: {
        labelStyle: {
          color: "transparent",
        },
        style: { backgroundColor: "transparent" },
      },
      plotOptions: {
        column: {
          maxPointWidth: 20,
          stacking: "normal",
        },
        series: {
          pointPlacement: "on",
        },
      },
    };
  }, [
    timezone,
    height,
    timezoneLabel,
    chartType,
    setChartState,
    chartState.granularity,
  ]);

  return (
    <div
      style={{
        height: height,
        backgroundColor: "rgb(37, 42, 49)",
        borderRadius: 4,
        padding: 20,
        color: "white",
      }}
    >
      <div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              fontSize: 21,
              marginBottom: 10,
            }}
          >
            <b>Grid</b>
          </div>
          <div
            style={{
              width: "100%",
              flex: 1,
              textAlign: "right",
              fontSize: "16px",
              marginBottom: 10,
              color: "rgba(255,255,255,.75)",
              marginTop: 4,
            }}
          >
            <FormControl
              sx={{ width: 140 }}
              disabled={isLoading || isLoadingMetrics || isLoadingForecasts}
            >
              <Select
                id="chart-type-select"
                value={chartType}
                onChange={handleChartTypeChange}
                sx={{
                  height: 26,
                  fontSize: "0.875rem",
                  "& .MuiSelect-select": {
                    paddingTop: "3px",
                    paddingBottom: "3px",
                    textAlign: "left",
                  },
                  "& .MuiOutlinedInput-input": {
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: 200,
                      "& .MuiMenu-list": {
                        padding: "2px 0",
                      },
                    },
                  },
                }}
              >
                <MenuItem
                  value="Power"
                  sx={{
                    height: 26,
                    fontSize: "0.875rem",
                    padding: "4px 10px",
                  }}
                >
                  Power
                </MenuItem>
                <MenuItem
                  value="Energy"
                  sx={{
                    height: 26,
                    fontSize: "0.875rem",
                    padding: "4px 10px",
                  }}
                >
                  Energy
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <div>
        {isLoading && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: height - 100,
            }}
          >
            <Spinner />
          </div>
        )}
        {noDataAvailable ? (
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 260,
              width: "100%",
            }}
          >
            No data available
          </div>
        ) : (
          <div
            style={{
              visibility: isLoading ? "hidden" : "visible",
            }}
          >
            <HighchartsReact
              key={chartType}
              highcharts={Highcharts}
              constructorType={"stockChart"}
              options={chartOptions}
              ref={chartRef}
            />
            {(isLoadingMetrics || isLoadingForecasts) && (
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  justifyContent: "center",
                  height: height - 70,
                  marginTop: 45 - height,
                }}
              >
                <Spinner />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
