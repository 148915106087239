import { Tag } from "@blueprintjs/core";
import { Battery } from "@ec1/types/Battery";
import { useMemo } from "react";

interface StationStateTagProps {
  battery: Battery;
}
export default function BatteryStationStateTag({
  battery,
}: StationStateTagProps) {
  const status = useMemo(() => {
    return battery?.station_state ?? null;
  }, [battery]);

  if (status === "ONLINE") {
    return (
      <Tag intent="success" style={{ fontWeight: 500 }}>
        {"Online"}
      </Tag>
    );
  }
  if (status === "OFFLINE") {
    return (
      <Tag style={{ backgroundColor: "#666", color: "white", fontWeight: 500 }}>
        {"Offline"}
      </Tag>
    );
  }
  if (status === "FAULT") {
    return (
      <Tag
        style={{
          backgroundColor: "#ba3636",
          color: "white",
          fontWeight: 500,
        }}
      >
        {"Fault"}
      </Tag>
    );
  }
  return <div>{"-"}</div>;
}
